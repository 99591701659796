import React, { useState, useEffect } from 'react';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
// import { BatchHttpLink } from 'apollo-link-batch-http';
import { from } from 'apollo-link';
import { setContext } from 'apollo-link-context';
// import { getMainDefinition } from 'apollo-utilities';
// import { WebSocketLink } from 'apollo-link-ws';
import { ApolloProvider } from '@apollo/react-hooks';
import { useAuth } from 'hooks';
import apolloLogger from 'apollo-link-logger';

import packageInfo from '../../package.json';
import { getAnonymousId } from './segment';
import { getGA4ClientId } from './ga4';

// function getWebSocketLink(token) {
//   return new WebSocketLink({
//     uri: process.env.REACT_APP_API_URL.replace('http', 'ws'),
//     options: {
//       reconnect: true,
//       connectionParams: {
//         authToken: token,
//       },
//     },
//   });
// }

async function supportsWebp() {
  if (!window.createImageBitmap) return false;

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  const blob = await fetch(webpData).then(r => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false
  );
}

function getHttpLink(token, webpSupport) {
  return new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'same-origin',
    headers: {
      authorization: token && `Bearer ${token}`,
      accept: `application/json${webpSupport ? ',image/webp' : ''}`,
      'x-segment-anonymous-id': getAnonymousId(),
    },
  });
}

function getAnalyticsLink() {
  return setContext(async (op, { headers = {} }) => {
    try {
      const clientId = await getGA4ClientId();
      if (clientId)
        return {
          headers: { ...headers, 'x-mangas-io-ga4-clientid': clientId },
        };
    } catch (e) {
      console.warn(e);
    }
  });
}

function useWebp() {
  const [webpSupport, setSupport] = useState(false);
  useEffect(() => {
    (async () => {
      const res = await supportsWebp();
      setSupport(res);
    })();
  }, []);
  return webpSupport;
}

export default function ApiProvider(props) {
  const { children } = props;
  const { token } = useAuth();
  const webpSupport = useWebp();
  // const wsLink = getWebSocketLink(token);
  const httpLink = getHttpLink(token, webpSupport);
  const analyticsLink = getAnalyticsLink();
  // const link = split(
  //   // split based on operation type
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === 'OperationDefinition' &&
  //       definition.operation === 'subscription'
  //     );
  //   },
  //   // wsLink,
  //   httpLink
  // );
  const cache = new InMemoryCache({
    freezeResults: true,
    // dataIdFromObject: o => o._id,
  });

  const client = new ApolloClient({
    name: 'mangas.io-web',
    version: `${packageInfo.version}-${packageInfo.versionName}`,
    link:
      process.env.NODE_ENV === 'development'
        ? from([apolloLogger, analyticsLink, httpLink])
        : from([analyticsLink, httpLink]),
    cache,
    assumeImmutableResults: true,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
