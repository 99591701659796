import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiGiftOutline } from '@mdi/js';
import { RadioButton, Button, Text } from '@mangas-io/design-system';

import { Form } from 'UI';

export default function CodeSelect() {
  const history = useHistory();

  return (
    <Form>
      <div className="code-selection">
        <RadioButton onClick={() => history.push('/code/carte-cadeau')}>
          <Icon className="image" path={mdiGiftOutline} />
          <Text bold>Carte cadeau</Text>
        </RadioButton>
        <RadioButton onClick={() => history.push('/code/pass-culture')}>
          <img
            alt="Logo du Pass Culture"
            className="image"
            height="47"
            src="https://cdn.mangas.io/images/pass-culture-min.svg"
            style={{
              height: 'auto',
              width: '97%',
            }}
            width="139"
          />
          <Text bold>Pass culture</Text>
        </RadioButton>
        <Button
          onClick={() => history.goBack()}
          type="secondary"
          size="medium"
          label="Retour"
          fullWidth
        />
      </div>
    </Form>
  );
}
