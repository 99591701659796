import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from '@mangas-io/design-system';

import { Form } from 'UI';

export default function ValidateCode(props) {
  const { icon, label, placeholder, onSubmit, error, setError } = props;
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState();
  useEffect(() => {
    setLoading(false);
  }, [error]);
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        onSubmit(code);
      }}
    >
      <Form>
        {icon}
        <Input
          onChange={e => setCode(e.target.value)}
          placeholder={placeholder}
          label={label}
          className="divider"
          highlight={error && 'error'}
          helper={error}
        />
        <Button
          fullWidth
          className="divider"
          type="submit"
          variant="primary"
          label="Valider"
          loading={isLoading}
        />
        <Button
          fullWidth
          className="space-top"
          variant="secondary"
          label="Retour"
          onClick={() => history.goBack()}
        />
      </Form>
    </form>
  );
}
