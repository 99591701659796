import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BrowserView } from 'react-device-detect';

import { CarrouselWrapper } from 'Components';
import { MangaTile } from '@mangas-io/design-system';
import { FavoriteButtonConnected } from 'Components';
import { useResponsive } from 'hooks';
import { range, getResizedUrl, withPixelDensity, ViewportOnly } from 'utils';

import { Link } from 'react-router-dom';

import './MangaLine.scss';

function getTileSize(is) {
  if (is('xl')) return 'large';
  if (is('sm')) return 'medium';
  return 'small';
}

function getTileSizeInPixels(is) {
  if (is('xl')) return withPixelDensity(232);
  if (is('sm')) return withPixelDensity(180);
  return withPixelDensity(140);
}

function MangaLineWrapper(props) {
  return (
    <ViewportOnly>
      <MangaLine {...props} />
    </ViewportOnly>
  );
}

function MangaLineSkeleton({ title, tileSize }) {
  const { is } = useResponsive();
  return (
    <CarrouselWrapper
      title={title}
      loading={true}
      className="align-controls-with-pictures"
    >
      {range(4).map(key => (
        <MangaTile
          key={key}
          size={tileSize || getTileSize(is)}
          loading={true}
        />
      ))}
    </CarrouselWrapper>
  );
}

function MangaLine({
  loading,
  tileDecorator = () => null,
  isInView = true,
  mangas,
  title,
  subtitle,
  subtitleLink,
  tileSize = null,
}) {
  const { is } = useResponsive();
  if (loading) return <MangaLineSkeleton title={title} tileSize={tileSize} />;
  if (!mangas || mangas.length === 0) return null;
  if (!isInView) return <MangaLineSkeleton title={title} tileSize={tileSize} />;
  return (
    <CarrouselWrapper
      subtitle={subtitle}
      subtitleLink={subtitleLink}
      withShadow
      title={title}
      className={`align-controls-with-pictures tiles-${tileSize ||
        getTileSize(is)}`}
    >
      {mangas.map((manga, index) => (
        <Fragment key={manga._id}>
          {tileDecorator({ manga, index })}
          <Link to={`/lire/${manga.slug}`}>
            <MangaTile
              size={tileSize || getTileSize(is)}
              title={manga.title}
              subtitle={`${manga.categories
                .filter(cat => cat.level < 2)
                .sort((a, b) => a.level - b.level)
                .slice(0, 2)
                .map(tag => tag.label)
                .join(' · ')}`}
              picture={getResizedUrl(manga.thumbnail.url, {
                width: getTileSizeInPixels(is),
              })}
            >
              <BrowserView>
                <FavoriteButtonConnected
                  mangaId={manga._id}
                  size="small"
                  noLabel
                />
              </BrowserView>
            </MangaTile>
          </Link>
        </Fragment>
      ))}
    </CarrouselWrapper>
  );
}

MangaLine.propTypes = {
  loading: PropTypes.bool,
  manga: PropTypes.object,
  title: PropTypes.string,
};

MangaLine.defaultProps = {
  loading: false,
};

export default MangaLineWrapper;
