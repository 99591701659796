import React from 'react';
import { Link } from 'react-router-dom';
import { Bloc, Button } from '@mangas-io/design-system';
import { InstantSearch, Configure, useHits } from 'react-instantsearch-core';
import { ResponsiveText } from 'UI';
import { shuffle, searchClient, CustomErrorBoundary } from 'utils';
import { useProfile, useAuth, useBundles } from 'hooks';
import { MangaLine } from 'Components';
import './FavCategoriesRecoLine.scss';

function NoFavoriteCategory() {
  return (
    <div className="noFav">
      <ResponsiveText type="display" size="small" className="--title-s title">
        Notre sélection pour toi
      </ResponsiveText>
      <Bloc className="noFav-bloc">
        <ResponsiveText bold>
          Aide-nous à te proposer du contenu en accord avec tes goûts !
        </ResponsiveText>
        <Link to="/favoriteCategories">
          <Button label="Gérer mes recommandations" />
        </Link>
      </Bloc>
    </div>
  );
}

function Recos() {
  const { hits } = useHits();
  return (
    <MangaLine
      mangas={shuffle(hits)
        .slice(0, 10)
        .map(hit => ({
          ...hit,
          thumbnail: { url: hit.thumbnail },
        }))}
      title="Notre sélection pour toi"
      subtitle="Modifier"
      subtitleLink={'/favoriteCategories'}
    />
  );
}

function FavCategoriesRecoLine() {
  const { profile, loading } = useProfile();
  const { isAuthenticated } = useAuth();
  const { getAlgoliaBundlesFilters } = useBundles();

  const userFavoriteCategories =
    profile.favoriteCategories?.length > 0 &&
    profile.favoriteCategories.map(cat => cat.label);

  if (!isAuthenticated || loading) return null;
  if (isAuthenticated && !userFavoriteCategories) return <NoFavoriteCategory />;

  const searchFilter = userFavoriteCategories
    .map(cat => `categories: ${cat}`)
    .join(' OR ');

  const filters = getAlgoliaBundlesFilters(searchFilter);

  return (
    <InstantSearch searchClient={searchClient} indexName="PROD_Mangas">
      <Configure
        attributesToRetrieve={[
          'title',
          'thumbnail',
          'categories',
          'slug',
          '_id',
        ]}
        responseFields={['hits']}
        attributesToHighlight={[]}
        filters={filters}
        hitsPerPage={100}
      />
      <Recos />
    </InstantSearch>
  );
}

export default function FavCategoriesRecoLineShielded(props) {
  return (
    <CustomErrorBoundary>
      <FavCategoriesRecoLine {...props} />
    </CustomErrorBoundary>
  );
}
