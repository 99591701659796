import React, { useState } from 'react';
import gql from 'graphql-tag';
import { loadStripe } from '@stripe/stripe-js';
import { useRouteMatch, Link, Switch } from 'react-router-dom';

import {
  Footer,
  SEO,
  LandingUsersFeedback,
  LandingGiftcard,
  LandingFaq,
  CarrouselWrapper,
  Success,
} from 'Components';
import { RedeemPage } from 'Pages';
import { useMutation, useFeatureFlags } from 'hooks';
import { Page, ResponsiveText, OnboardingBackground } from 'UI';
import { PublicRoute } from 'utils';

import './Voucher.scss';
import { Button, Text } from '@mangas-io/design-system';

const GET_VOUCHER_CHECKOUT = gql`
  mutation checkoutVoucher($productId: ID!) {
    voucherCheckoutSession(productId: $productId) {
      id
      error
    }
  }
`;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StripeCTA({ productId, ...buttonProps }) {
  const [error, setError] = useState('');
  const [hasLoader, setHasLoader] = useState(false);

  const [checkout] = useMutation(GET_VOUCHER_CHECKOUT, {
    onCompleted: async data => {
      const stripe = await stripePromise;
      const { id, error } = data.voucherCheckoutSession || {};
      if (id) {
        setTimeout(async () => {
          await stripe.redirectToCheckout({
            sessionId: id,
          });
        }, 1500);
      } else if (error) {
        setError(error);
      }
    },
  });
  if (error)
    return (
      <div className="error">
        <p>Une erreur a eu lieu:</p>
        <p>{error}</p>
      </div>
    );
  return (
    <Button
      {...buttonProps}
      loading={hasLoader}
      disabled={hasLoader}
      onClick={() => {
        setHasLoader(true);
        checkout({
          variables: { productId: productId },
        });
      }}
    />
  );
}

function VoucherPageSuccess() {
  // TODO : event tracking ?
  return (
    <OnboardingBackground id="mio-loginPage">
      <Success>
        <Text bold>Merci beaucoup !</Text>
        <Text>
          Tu recevras rapidement une confirmation par email. Bonne lecture sur
          Mangas.io !
        </Text>
        <Link to="/">
          <Button
            fullWidth
            className="divider"
            variant="primary"
            label="Continuer"
          />
        </Link>
      </Success>
    </OnboardingBackground>
  );
}

function VoucherPage() {
  const flags = useFeatureFlags(['gift_cards']);

  const offers = JSON.parse(flags?.gift_cards?.value || '[]');

  return (
    <>
      <SEO
        title="Carte cadeau Mangas.io - Offre des centaines de mangas en illimité !"
        description="Fais plaisir à tes ami.e.ss fans de manga : offre 3 mois de mangas à lire en illimité sur Mangas.io et obtiens 3 cartes à collectionner en cadeaux !"
      />
      <Page id="VoucherPageNew">
        <div className="header">
          <img
            className="logo"
            src="https://cdn.mangas.io/images/Hero-image-Yuan.png"
            alt="gift-Yuan"
          />
          <ResponsiveText
            as="h1"
            className="titleVoucher"
            type="display"
            size="medium"
            bold
          >
            Offre des <b className="highlight">centaines de mangas !</b>
          </ResponsiveText>
          <ResponsiveText
            as="h2"
            className="subtitle"
            type="display"
            size="small"
          >
            Voici une <strong>idée cadeau originale à petit prix</strong> pour
            tes ami.e.s fan de mangas : des mangas à lire en illimité sur
            Mangas.io !
          </ResponsiveText>
        </div>
        <div className="price">
          {offers.map(offer => (
            <div
              key={offer.stripe_price_id}
              className="priceBloc"
              style={{
                background: `linear-gradient(${offer.gradient})`,
              }}
            >
              <Text type="display" size="small" bold>
                {offer.title}
              </Text>
              <div className="tag">{offer.pill}</div>
              <img src={offer.image} height="116" alt="card preview" />
              <Text className="tag tagWhite textDarkBlue">
                {offer.subtitle}
              </Text>
              <Text className="priceText">{offer.price}</Text>

              <StripeCTA
                productId={offer.stripe_price_id}
                className="btnOffer"
                size="large"
                label={offer.cta}
                variant="primary"
              />

              {offer.secondary_cta && (
                <a href={offer.secondary_cta_link}>
                  <Button label={offer.secondary_cta} />
                </a>
              )}
              {offer.footer && (
                <Text className="subText" size="small">
                  {offer.footer}
                </Text>
              )}
            </div>
          ))}
        </div>
        <div className="header activation">
          <ResponsiveText
            as="h1"
            className="titleVoucher"
            type="display"
            size="medium"
            bold
          >
            J'active <b className="highlight"> ma carte cadeau !</b>
          </ResponsiveText>
          <ResponsiveText
            as="h2"
            className="subtitle"
            type="text"
            size="medium"
          >
            J'ai reçu une carte cadeau <strong>Mangas.io</strong> et je souhaite
            en bénéficier ?
          </ResponsiveText>
          <img
            className="coffeeImg"
            alt="coffee question"
            src="https://cdn.mangas.io/landing/coffee-question-mark.png"
            width={200}
          />
          <Link to="/carte-cadeau/activation">
            <Button
              className="activationBtn"
              size="large"
              variant="primary"
              label="Activation"
            />
          </Link>
        </div>
        <LandingUsersFeedback
          title={
            <Text
              as="h2"
              className="titleVoucher"
              type="display"
              size="large"
              bold
            >
              La <b className="title-feedback">meilleure expérience</b>{' '}
              de manga…
            </Text>
          }
        />
        <LandingGiftcard
          title={
            <span>
              ...avec des <b className="text-gold">cartes collector</b>{' '}
              exclusives
            </span>
          }
        />

        <div className="process-collector">
          <ResponsiveText
            type="display"
            size="medium"
            bold
            className="process-title"
          >
            Comment ça marche ?
          </ResponsiveText>
          <CarrouselWrapper title="PACK COLLECTOR">
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/card.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Tu commandes ton pack collector sur Mangas.io.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/plane.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Mélanie s’occupe de préparer, d’envoyer ta commande et de te
                transmettre le numéro de suivi.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/gift.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Tu reçois ton pack contenant tes 3 cartes collector ainsi qu’un
                flyer t'expliquant comment activer tes codes.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/device.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Une fois tes codes activés, tu accèdes à des centaines de mangas
                en illimité !
              </Text>
            </div>
          </CarrouselWrapper>
          <CarrouselWrapper title="CARTE DIGITALE">
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/card.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Tu commandes ta carte cadeau digitale sur Mangas.io.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/plane.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Mélanie s’occupe de t'envoyer une confirmation de commande ainsi
                que ton code d’accès par mail.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/gift.png"
                alt=""
                height="60"
              />
              <Text size="small">
                [OPTIONNEL] Imprime le mail contenant le code cadeau et offre-le
                à ton.a proche.
              </Text>
            </div>
            <div className="card arrow">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/arrow.png"
                alt=""
                width="50"
              />
            </div>
            <div className="card">
              <img
                className="logo"
                src="https://cdn.mangas.io/images/icons/device.png"
                alt=""
                height="60"
              />
              <Text size="small">
                Une fois ton code activé, tu accèdes à des centaines de mangas
                en illimité !
              </Text>
            </div>
          </CarrouselWrapper>
        </div>

        <LandingFaq title="Questions fréquentes" page_name="faq_carte_cadeau" />
      </Page>
      <Footer />
    </>
  );
}

export default function VoucherPageRouter() {
  const match = useRouteMatch();

  return (
    <Switch>
      <PublicRoute
        path={`${match.path}/success`}
        component={<VoucherPageSuccess />}
      />
      <PublicRoute
        path={[`${match.path}/activation/:code`, `${match.path}/activation`]}
        component={<RedeemPage />}
      />
      <PublicRoute
        path={[match.path, `${match.path}/cancelled`]}
        component={<VoucherPage />}
      />
    </Switch>
  );
}
