import algoliasearch from 'algoliasearch/lite';
import aa from 'search-insights';
import recommend from '@algolia/recommend';

/*
 * Note :
 * Pour pouvoir filtrer selon une propriété, il faut qu'elle soit
 * configurée comme "facet" dans l'interface algolia
 */
export function formatBundlesToAlgoliaFilters(bundles) {
  const allowedBundlesIds = bundles
    .filter(bundle => bundle.access !== 'BLOCK')
    .map(bundle => bundle._id);
  const blockedBundles = bundles
    .filter(bundle => bundle.access === 'BLOCK')
    .map(bundle => bundle._id);

  const allowedBundlesFilters = allowedBundlesIds
    .map(bundleId => `bundles:${bundleId}`)
    .join(' OR ');

  const blockedBundlesFilters = blockedBundles
    .map(bundleId => `NOT bundles:${bundleId}`)
    .join(' OR ');

  return mergeAlgoliaFilters(
    allowedBundlesFilters,
    blockedBundlesFilters,
    'AND'
  );
}

export function mergeAlgoliaFilters(filterA, filterB, action = 'AND') {
  if (!filterA || filterA.length === 0) return filterB;
  if (!filterB || filterB.length === 0) return filterA;
  return `(${filterA}) ${action} (${filterB})`;
}

export const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

export const recommendClient = recommend(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
});
