import React, { useEffect, useRef } from 'react';

import classnames from 'classnames';
import {
  InstantSearch,
  Configure,
  useSearchBox,
  useHits,
} from 'react-instantsearch';
import { Link } from 'react-router-dom';
import { InlineCard, Tag, Text } from '@mangas-io/design-system';

import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';

import { FloatingMenu } from 'UI';
import {
  getResizedUrl,
  withPixelDensity,
  pluralize,
  searchClient,
  debounce,
  track,
} from 'utils';
import { useResponsive, useBundles } from 'hooks';

import './Search.scss';

function LoadingIndicator() {
  const { is } = useResponsive();
  return [...Array(3).keys()].map(key => (
    <InlineCard key={key} loading={true} size={is('md') ? 'medium' : 'small'} />
  ));
}

const SearchInput = ({ active, onClose }) => {
  const inputRef = useRef();
  const { is } = useResponsive();
  const { query, refine } = useSearchBox();

  useEffect(() => {
    if (active) inputRef.current?.focus();
  }, [active]);

  const handleInputChange = event => {
    const value = event.currentTarget.value;
    refine(value);
  };

  return (
    <div className="SearchInput">
      <input
        ref={inputRef}
        placeholder="Chercher un titre, un style, un.e auteur.ice..."
        defaultValue={query}
        onChange={handleInputChange}
      />
      {!is('lg') && <Icon onClick={onClose} path={mdiCloseCircle} />}
    </div>
  );
};

const Hit = ({ hit, onClick }) => {
  const { is } = useResponsive();
  const publisherString = hit.publisher_vf ? `${hit.publisher_vf}  · ` : '';
  const chapterCountString = `${hit.chapterCount} ${pluralize(
    'chapitre',
    'chapitres',
    hit.chapterCount
  )}`;
  return (
    <Link key={hit.slug} to={`/lire/${hit.slug}`} onClick={onClick}>
      <InlineCard
        picture={getResizedUrl(hit.thumbnail, {
          width: withPixelDensity(is('md') ? 180 : 140),
        })}
        subtitle={`${publisherString}${chapterCountString}`}
        title={hit.title}
      >
        {hit.categories.slice(0, 3).map(cat => (
          <Tag key={cat} size="small">
            {cat}
          </Tag>
        ))}
      </InlineCard>
    </Link>
  );
};

const Search = ({ onClose = () => {}, className }) => {
  const { hits } = useHits();
  const { query, isSearchStalled: loading } = useSearchBox();

  const emptyQuery = query.length === 0;

  useEffect(() => {
    const sendMangaListViewedEvent = () => {
      if (hits.length > 0) {
        track('Manga List Viewed', {
          index: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas`,
          eventType: 'view',
          queryID: hits[0].__queryID,
          objectIDs: hits.map(hit => hit.objectID),
          positions: hits.map(hit => hit.__position),
        });
      }
    };

    const debouncedSendEvent = debounce(sendMangaListViewedEvent, 500);
    debouncedSendEvent();

    return () => {
      debouncedSendEvent.cancel();
    };
  }, [hits]);

  return (
    <div className={classnames(className, 'slide', 'search-result-slide')}>
      <FloatingMenu.Header>
        <SearchInput active onClose={onClose} />
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        {!emptyQuery && loading && <LoadingIndicator />}
        {!emptyQuery &&
          hits.map(hit => (
            <Hit
              key={hit.objectID}
              hit={hit}
              onClick={() => {
                track('Manga Clicked', {
                  index: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas`,
                  eventType: 'click',
                  queryID: hit.__queryID,
                  objectID: hit.objectID,
                  position: hit.__position,
                });
                onClose();
              }}
            />
          ))}

        {emptyQuery && (
          <div className="no-result">
            <Text bold>Trouve ton prochain manga</Text>
            <Text size="small">
              Plutôt action, romance, magie, thriller... Trouve le manga qui
              captivera tes prochains jours !
            </Text>
          </div>
        )}

        {hits.length === 0 && (
          <div className="no-result">
            <Text>Aucun résultat</Text>
          </div>
        )}
      </FloatingMenu.Body>
    </div>
  );
};

const SearchWrapper = props => {
  const { getAlgoliaBundlesFilters } = useBundles();
  const filters = getAlgoliaBundlesFilters();
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas`}
    >
      <Configure
        filters={filters}
        hitsPerPage={100}
        clickAnalytics
        attributesToRetrieve={[
          'title',
          'thumbnail',
          'categories',
          'slug',
          '_id',
          'publisher_fr',
          'chapterCount',
        ]}
        responseFields={['hits']}
        attributesToHighlight={[]}
      />
      <Search {...props} />
    </InstantSearch>
  );
};

export default SearchWrapper;
