import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiCreditCardOutline,
  mdiAccountCheck,
  mdiAccountCancel,
} from '@mdi/js';

import { Text } from '@mangas-io/design-system';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';

const SubscriptionManagementSlide = props => {
  const { navigation, className } = props;
  const { profile, loading } = useProfile({
    subscription: true,
  });
  if (loading) return null;

  return (
    <div className={classnames(className, 'slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Gérer mon abonnement
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div
          className="row clickable"
          onClick={() => {
            navigation.push('payment_method');
          }}
        >
          <span className="settings-icon">
            <Icon path={mdiCreditCardOutline} />
          </span>
          <Text>Moyen de paiement</Text>
        </div>
        <div
          className="row clickable"
          onClick={() => navigation.push('cancel_subscription')}
        >
          <span className="settings-icon">
            <Icon
              path={
                profile.info.status === 'canceling'
                  ? mdiAccountCheck
                  : mdiAccountCancel
              }
            />
          </span>
          <Text>
            {profile.info.status === 'canceling'
              ? 'Réactivation'
              : 'Résiliation'}
          </Text>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default SubscriptionManagementSlide;
