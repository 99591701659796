import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

import { FloatingMenu } from 'UI';
import { Button, Text } from '@mangas-io/design-system';
import { useProfile } from 'hooks';
import { dayjs } from 'utils';

import './CancelSubscriptionSlide.scss';

const CancelSubscriptionSlide = props => {
  const { navigation, className } = props;
  const {
    profile,
    loading,
    cancelSubscription,
    uncancelSubscription,
  } = useProfile({
    subscription: true,
  });
  const [toggleLoading, setToggleLoading] = useState(false);
  if (loading) return null;

  const isCancelling = profile.info.status === 'canceling';

  return (
    <div
      className={classnames(className, 'slide', 'cancel-subscription-slide')}
    >
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        {isCancelling ? 'Réactiver' : 'Résilier'} mon abonnement
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div>
          {profile.info.status !== 'canceling' && (
            <Text size="small">
              Tu vas résilier ton abonnement.
              <br />
              Tout prélèvement à venir sera annulé.
              <br />
              Ton abonnement restera actif jusqu'au{' '}
              {dayjs(profile.info.end_date).format('LL')}.
            </Text>
          )}
          {isCancelling && (
            <Text size="small">
              Tu vas réactiver ton abonnement.
              <br />
              Les prélèvements seront réactivés.
            </Text>
          )}
        </div>
        <div className="actions">
          <Button
            loading={toggleLoading}
            disabled={toggleLoading}
            variant="primary"
            fullWidth
            label={isCancelling ? 'Réactiver' : 'Résilier'}
            onClick={async () => {
              if (toggleLoading) return;
              setToggleLoading(true);
              if (isCancelling) await uncancelSubscription();
              else await cancelSubscription();
              navigation.pop(2);
              setTimeout(() => {
                // in a timeout so it waits for the screen transition to prevent missclicks
                setToggleLoading(false);
              }, 500);
            }}
          />
          <Button label="Annuler" fullWidth onClick={() => navigation.pop()} />
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default CancelSubscriptionSlide;
