import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiLoading,
  mdiImagePlus,
  mdiLockReset,
  mdiClose,
  mdiTrashCan,
} from '@mdi/js';
import { Image } from 'cloudinary-react';
import { Text } from '@mangas-io/design-system';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';

import './ProfileSlide.scss';

const ProfileSlide = props => {
  const { navigation, updateAvatar, flags } = props;
  const { profile, updateMe } = useProfile();

  const default_avatar = process.env.REACT_APP_CLOUDINARY_DEFAULT_AVATAR;
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [avatar, setAvatar] = useState(profile.avatar?.url || default_avatar);
  const [avatarError, setAvatarError] = useState('');

  const uploadImage = async event => {
    const files = event.target.files;
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('upload_preset', 'user_avatar');
    setAvatarLoading(true);
    setAvatarError('');

    const resp = await fetch(process.env.REACT_APP_CLOUDINARY_UPLOAD_URL, {
      method: 'post',
      body: formData,
    }).catch(err => {
      console.log(err.response.data);
    });
    if (!resp.ok) {
      console.log(resp);
      setAvatarError(
        `Une erreur s'est produite. veuillez réessayer ultérieurement. (${resp.status})`
      );
      setAvatarLoading(false);
      return;
    }
    const data = await resp.json();

    if (data.existing) {
      setAvatarError(
        `Une erreur s'est produite. veuillez réessayer ultérieurement. (existing:true)`
      );
    }
    if (data.error) {
      console.log(data.error.message);
      setAvatarError(
        `Une erreur s'est produite. veuillez réessayer ultérieurement.`
      );
    } else {
      await updateProfileAvatar(data.secure_url);
    }
    setAvatarLoading(false);
  };

  const updateProfileAvatar = async url => {
    await updateMe({
      variables: {
        input: {
          avatar: {
            default_url: url,
          },
        },
      },
    });
    setAvatar(url);
  };

  const destroyAvatar = async event => {
    event.preventDefault();
    await updateMe({
      variables: {
        input: {
          avatar: {
            default_url: default_avatar,
          },
        },
      },
    });
    setAvatar(default_avatar);
  };

  useEffect(() => {
    if (avatar) {
      updateAvatar(avatar);
    }
  }, [avatar, updateAvatar]);

  const ImageOnError = useCallback(() => {
    setAvatar(default_avatar);
  }, [default_avatar]);

  return (
    <div className={classnames(props.className, 'slide', 'profile-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Profil
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="profile-row">
          <label className="profile-avatar clickable" htmlFor="user_avatar">
            <Image
              cloudName="mangasio"
              publicId={avatar}
              width="110"
              secure="true"
              alt="User avatar"
              onError={ImageOnError}
            />
            {avatarLoading && (
              <div className="loading">
                <Icon className="--spin" spin={1.5} path={mdiLoading} />
              </div>
            )}
          </label>
          {!flags.noProfile && <Text>{profile.email}</Text>}
        </div>
        {avatarError && (
          <div className="row error">
            <Text mobile size="small">
              {avatarError}
            </Text>
            <span
              className="settings-icon close clickable"
              onClick={() => {
                setAvatarError('');
              }}
            >
              <Icon path={mdiClose} />
            </span>
          </div>
        )}
        <label className="row clickable action-row" htmlFor="user_avatar">
          <span className="settings-icon">
            <Icon path={mdiImagePlus} />
          </span>
          <Text>Modifier l'avatar</Text>

          {avatar !== default_avatar && (
            <Text className="destroy-avatar" onClick={destroyAvatar}>
              Supprimer
            </Text>
          )}

          <input
            type="file"
            id="user_avatar"
            name="image"
            accept="image/png, image/jpeg"
            onChange={uploadImage}
          />
        </label>
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('change_password')}
          >
            <span className="settings-icon">
              <Icon path={mdiLockReset} />
            </span>
            <Text>Modifier mon mot de passe</Text>
          </div>
        )}
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('remove_account')}
          >
            <span className="settings-icon">
              <Icon path={mdiTrashCan} />
            </span>
            <Text>Supprimer mon compte</Text>
          </div>
        )}
      </FloatingMenu.Body>
    </div>
  );
};

export default ProfileSlide;
