import { useState } from 'react';
import classNames from 'classnames';
import { Checkbox, Text, Disclaimer } from '@mangas-io/design-system';
import Icon from '@mdi/react';
import { mdiCheck, mdiCog } from '@mdi/js';
import { useRecoilValue } from 'recoil';

import { FloatingMenu } from 'UI';
import { useReaderSettings, useResponsive } from 'hooks';

import currentReaderTypeState from 'states/current-reader-type.state';

import IconReaderModeSingle from 'assets/reader-mode-single.svg';
import IconReaderModeDouble from 'assets/reader-mode-double.svg';
import IconReaderModeFilm from 'assets/reader-mode-film.svg';
import IconReaderModeScroll from 'assets/reader-mode-scroll.svg';

import './ReaderSettings.scss';

export default function ReaderSettings({
  className,
  withToggle = false,
  onClose = () => {},
}) {
  const [{ reader, quality, reducedMotion }, setSettings] = useReaderSettings();
  const [isOpen, setIsOpen] = useState(!withToggle);
  const { is } = useResponsive();
  const currentReaderType = useRecoilValue(currentReaderTypeState);

  const isDesktop = is('lg');
  const setSettingsAndReload = settings => {
    setSettings(settings);
    window.location.reload();
  };

  function updateSettings(settings) {
    if (currentReaderType === 'webtoon') return;
    setSettings(settings);
  }

  return (
    <div className={classNames(' reader-settings', className)}>
      <FloatingMenu.Header
        onClick={() => {
          if (withToggle) setIsOpen(current => !current);
        }}
      >
        <div className="header">
          <span>Paramètres de lecture</span>
          <Icon path={mdiCog} />
        </div>
      </FloatingMenu.Header>
      {isOpen && (
        <FloatingMenu.Body>
          <div className="reader-settings-container">
            <Text size="small">Défilement des pages</Text>
            <div className="reader-modes">
              <div
                className={classNames('mode-card clickable ', {
                  active: reader === 'page',
                  faded: currentReaderType === 'webtoon',
                })}
                style={{
                  backgroundImage: `url(${IconReaderModeSingle})`,
                }}
                onClick={() => updateSettings({ reader: 'page' })}
              >
                <Text>Simple page</Text>
              </div>
              {isDesktop && (
                <div
                  className={classNames('mode-card clickable', {
                    active: reader === 'page_double',
                    faded: currentReaderType === 'webtoon',
                  })}
                  style={{
                    backgroundImage: `url(${IconReaderModeDouble})`,
                  }}
                  onClick={() => updateSettings({ reader: 'page_double' })}
                >
                  <Text>Double page</Text>
                </div>
              )}
              {isDesktop && (
                <div
                  className={classNames('mode-card clickable', {
                    active: reader === 'horizontal',
                    faded: currentReaderType === 'webtoon',
                  })}
                  style={{
                    backgroundImage: `url(${IconReaderModeFilm})`,
                  }}
                  onClick={() => updateSettings({ reader: 'horizontal' })}
                >
                  <Text>Pellicule</Text>
                </div>
              )}
              <div
                className={classNames('mode-card clickable', {
                  active: reader === 'vertical',
                })}
                style={{
                  backgroundImage: `url(${IconReaderModeScroll})`,
                }}
                onClick={() => updateSettings({ reader: 'vertical' })}
              >
                <Text>Scroll</Text>
              </div>
            </div>
            {currentReaderType === 'webtoon' && (
              <div style={{ marginBottom: '20px' }}>
                <Disclaimer text="Ce titre ne peut être lu qu'en défilement vertical" />
              </div>
            )}
            <Text size="small">Qualité</Text>
            <div className="quality-modes">
              <div
                className="mode-row clickable"
                onClick={() => setSettingsAndReload({ quality: 'hd' })}
              >
                <div className="content">
                  <Text size="small" mobile>
                    Haute définition
                  </Text>
                  <Text className="faded" size="small" mobile>
                    La plus haute qualité d’image disponible, les données
                    téléchargées ainsi que les temps de chargement seront plus
                    importants.
                  </Text>
                </div>
                <div className="icon">
                  {quality === 'hd' && <Icon path={mdiCheck} />}
                </div>
              </div>
              <div
                className="mode-row clickable"
                onClick={() => setSettingsAndReload({ quality: 'default' })}
              >
                <div className="content">
                  <Text size="small" mobile>
                    Optimisée (recommandée)
                  </Text>
                  <Text className="faded" size="small" mobile>
                    Images de bonne qualité en format et taille amoindrie pour
                    un affichage accéléré.
                  </Text>
                </div>
                <div className="icon">
                  {quality === 'default' && <Icon path={mdiCheck} />}
                </div>
              </div>
            </div>
            <div className="animate-container">
              <Text size="small">Accessibilité</Text>
              <Checkbox
                className="checkbox-reduced-motion"
                checked={reducedMotion}
                onChange={() => {
                  updateSettings({ reducedMotion: !reducedMotion });
                }}
              >
                <Text size="small">Réduire les animations</Text>
              </Checkbox>
            </div>
          </div>
        </FloatingMenu.Body>
      )}
      {!isOpen && <div className="mask" onClick={onClose} />}
    </div>
  );
}
