import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChapterCard, Separator } from '@mangas-io/design-system';

import { useProfile, useInterval, useAuth, useBundles } from 'hooks';
import { dayjs, pluralize, CustomErrorBoundary } from 'utils';

import './ChapterList.scss';

// Retourne un lien si cond == true, sinon retourne uniquement le contenu
const AutoLink = ({ to, cond, children }) => {
  if (cond) {
    return <Link to={to}>{children}</Link>;
  }
  return children;
};

const Countdown = ({ date, refreshParent }) => {
  const [delay, setDelay] = useState(dayjs(date).unix() - dayjs().unix() + 1);

  useInterval(() => {
    setDelay(delay => delay - 1);
  }, 1000);

  if (delay < 1) {
    refreshParent();
  }

  const hours = Math.floor(delay / 3600);
  const minutes = Math.floor((delay % 3600) / 60);
  const seconds = Math.floor(delay % 60);

  const displayedHours = hours > 0 ? `${hours}h ` : '';
  const displayedMinutes = hours > 0 || minutes > 0 ? `${minutes}m ` : '';

  return `Sortie dans ${displayedHours}${displayedMinutes}${seconds}s`;
};

const getChapterSubtitle = (chapter, refreshParent) => {
  const today = dayjs();
  const tomorrow = dayjs().add(1, 'day');

  if (!chapter.releaseDate) return 'Disponible prochainement';

  if (chapter.releaseDate && today.isBefore(chapter.releaseDate)) {
    if (dayjs(chapter.releaseDate).isBefore(tomorrow))
      return (
        <Countdown date={chapter.releaseDate} refreshParent={refreshParent} />
      );
    return `Sortie le ${dayjs(chapter.releaseDate).format('DD/MM/YYYY')}`;
  }

  return `${chapter.pageCount} ${pluralize(
    'page',
    'pages',
    chapter.pageCount
  )}`;
};

const ChapterRow = props => {
  const { chapter, slug } = props;
  const { profile } = useProfile();
  const { isAuthenticated } = useAuth();
  const { bundleAccess } = useBundles();
  const [key, setkey] = useState(Math.random());
  const refresh = () => setkey(Math.random());
  const bundle = bundleAccess(chapter.bundles);
  function getBadge(chapter) {
    if (bundle === 'LIST' && chapter.access === 'PAID') return 'locked';
    if (profile.isActive) return null;
    if (chapter.access === 'OPEN') return 'read';
    if (isAuthenticated && chapter.access === 'FREE') return 'read';
    if (chapter.access === 'FREE') return 'login';
    return 'signup';
  }
  const alertLimit = dayjs().subtract(4, 'days');
  const today = dayjs();

  if (chapter.isSeparator)
    return (
      <div className="chapter-separator">
        <Separator>{chapter.title}</Separator>
      </div>
    );
  const badge = getBadge(chapter);
  return (
    <AutoLink
      to={`/lire/${slug}/${chapter.number}/1`}
      cond={dayjs(chapter.releaseDate).isBefore(today)}
    >
      <ChapterCard
        key={key}
        alert={
          dayjs(chapter.releaseDate).isAfter(alertLimit) &&
          dayjs(chapter.releaseDate).isBefore(today)
        }
        faded={
          chapter.isRead ||
          !chapter.releaseDate ||
          dayjs().isBefore(chapter.releaseDate) ||
          badge === 'locked'
        }
        subtitle={getChapterSubtitle(chapter, refresh)}
        title={chapter.title}
        number={chapter.isBonus ? null : chapter.number}
        badge={badge}
      />
    </AutoLink>
  );
};

const ChapterList = ({ chapters, displayChapters = true, slug }) => {
  return (
    <div
      className="chapter-container"
      style={{
        //80 = Hauteur de chaque ligne de chapitre
        // A adapter en cas de changement de design
        height: displayChapters ? 80 * chapters.length : 0,
      }}
    >
      {chapters.map(chapter => (
        <ChapterRow chapter={chapter} key={chapter._id} slug={slug} />
      ))}
    </div>
  );
};

export default function ShieldedChapterList(props) {
  return (
    <CustomErrorBoundary>
      <ChapterList {...props} />
    </CustomErrorBoundary>
  );
}
