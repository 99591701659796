import React, { useState } from 'react';
// import { Tag } from '@mangas-io/design-system';
import { Tag } from 'UI';
import { dayjs } from 'utils';
import { Information } from 'UI';
import { Link } from 'react-router-dom';
import './MangaInfo.scss';

const { Row, ReadMore } = Information;

const MangaInfo = ({ manga, ...props }) => {
  const sortedMangas = [...(manga.volumes || [])].sort((a, b) =>
    dayjs(a.publicationDate).isBefore(b.publicationDate) ? -1 : 1
  );
  const firstMangaPublication = [...sortedMangas].shift();
  const lastMangaPublication = [...sortedMangas].pop();

  const publisher_vo =
    manga?.publishers?.filter(
      ({ publisher }) => publisher.countryCode !== 'fr'
    ) || [];
  const publisher_fr =
    manga?.publishers?.filter(
      ({ publisher }) => publisher.countryCode === 'fr'
    ) || [];

  const { categories = [], alternativeTitles = [] } = manga;
  const [showCategories, setShowCategories] = useState(false);
  const onClick = () => setShowCategories(true);
  const types = categories.filter(cat => cat.level === 0);
  const genres = categories.filter(cat => cat.level === 1);
  const sousCategories = categories.filter(cat => cat.level === 2);

  return (
    <Information className="MangaInfo">
      <Row title="Résumé">{manga.description}</Row>
      {manga.authors && (
        <Row title="Créateur(s)">
          {manga.authors.map((author, index) => {
            return (
              <Link to={`/author/${author.slug}`} key={author._id}>
                {author.name}
                {manga.authors.length > 1 &&
                  index < manga.authors.length - 1 &&
                  ', '}
              </Link>
            );
          })}
        </Row>
      )}

      <ReadMore>
        <Row title="Type de manga">
          {types.map((item, i) => (
            <Tag
              isFavorite={item.isFavorite}
              slug={item.slug}
              key={i}
              value={item}
            >
              {item.label}
            </Tag>
          ))}
        </Row>
        <Row title="Genre(s)" className="categories">
          {genres.map((item, i) => (
            <Tag
              isFavorite={item.isFavorite}
              slug={item.slug}
              key={i}
              value={item}
            >
              {item.label}
            </Tag>
          ))}
          {showCategories &&
            sousCategories.map((item, i) => (
              <Tag
                isFavorite={item.isFavorite}
                slug={item.slug}
                key={i}
                value={item}
              >
                {item.label}
              </Tag>
            ))}
          {!showCategories && sousCategories.length > 0 && (
            <Tag onClick={onClick} id="tag-secondary">
              + Voir plus
            </Tag>
          )}
        </Row>
        <Row title="Âge recommandé">
          {manga.age ? manga.age : "Aucune limite d'âge"}
        </Row>
        {publisher_vo.length > 0 && (
          <Row title="Publication originale" className="vertical">
            {publisher_vo.map(elem => (
              <span key={elem.publisher._id}>
                <Link to={`/publisher/${elem.publisher.slug}`}>
                  {elem.publisher.name}
                </Link>
                | {dayjs(elem.releaseDate).format('YYYY')}
              </span>
            ))}
          </Row>
        )}
        {publisher_fr.length > 0 && (
          <Row title="Publication française" className="vertical">
            {publisher_fr.map(elem => (
              <span key={elem.publisher._id}>
                <Link to={`/publisher/${elem.publisher.slug}`}>
                  {elem.publisher.name}
                </Link>
                | {dayjs(elem.releaseDate).format('YYYY')}
              </span>
            ))}
          </Row>
        )}
        <Row title="Années de publication">
          {firstMangaPublication &&
            dayjs(firstMangaPublication.publicationDate).format('YYYY')}{' '}
          -{' '}
          {lastMangaPublication &&
            dayjs(lastMangaPublication.publicationDate).format('YYYY')}
        </Row>
        {alternativeTitles.length > 0 && (
          <Row title="Autres noms">{manga.alternativeTitles.join(', ')}</Row>
        )}
      </ReadMore>
    </Information>
  );
};

export default MangaInfo;
