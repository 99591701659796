import classnames from 'classnames';
import React from 'react';

import './PricingLine.scss';

function PricingLine({ className, ...props }) {
  return <div className={classnames('pricing-line', className)} {...props} />;
}

export default PricingLine;
