import React from 'react';

import Icon from '@mdi/react';
import { mdiDiscord, mdiHelpCircle, mdiLoginVariant } from '@mdi/js';
import { trackHandler } from 'utils';

import './Settings.scss';

function SettingsOffline({ logout }) {
  return (
    <div className="Settings floating-container">
      <div className="slide displayed">
        <div className="row header withBorder">
          <h1 className="--subtitle">COMPTE</h1>
        </div>
        <div className="floating-wrapper">
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/975cZgh"
            onClick={(e) => trackHandler(e, "Discord Clicked")}
          >
            <span className="icon">
              <Icon path={mdiDiscord} />
            </span>
            <span className="label">Rejoindre notre discord</span>
          </a>
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/Aide-c31616b7c57d4959a367d9bb6be6a4d6"
            onClick={(e) => trackHandler(e, "Help Clicked")}
          >
            <span className="icon">
              <Icon path={mdiHelpCircle} />
            </span>
            <span className="label">Aide</span>
          </a>
          <div className="row clickable" onClick={logout}>
            <span className="icon">
              <Icon path={mdiLoginVariant} />
            </span>
            <span className="label">Se déconnecter</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsOffline;
