import React from 'react';
import { CtaImage } from '@mangas-io/design-system';
import { StatusSwitch } from 'UI';
import { getResizedUrl, withPixelDensity } from 'utils';
import { useFlags } from 'hooks';

import './NudgeColumn.scss';

function FreeUserColumn() {
  const { flag: noPromoFlag } = useFlags('NO_PROMO');
  if (noPromoFlag) return null;
  return (
    <CtaImage
      className="activation-demo-img"
      link="/abonnement"
      imageLink={getResizedUrl(
        'https://cdn.mangas.io/cta/nudge-banner-free.png',
        {
          width: withPixelDensity(380),
        }
      )}
      altText="Activer mes 7 jours gratuits"
    />
  );
}

function NudgeColumn() {
  return (
    <div className="nudge-column">
      <StatusSwitch>
        <FreeUserColumn status="FREE|GUEST" />
      </StatusSwitch>
    </div>
  );
}

export default NudgeColumn;
