import React, { useState } from 'react';
import gql from 'graphql-tag';

import { MangaCardsReleases } from 'Components';
import { useQuery } from 'hooks';
import { IndicatorPill, Button } from '@mangas-io/design-system';
import { CustomErrorBoundary, dayjs } from 'utils';

const GQL_GET_NEW_RELEASES = gql`
  query getReleases {
    releases {
      _id
      manga {
        _id
        slug
        title
        categories {
          _id
          level
          label
          slug
          isFavorite
        }
      }
      firstChapterNumber
      lastChapterNumber
      releaseDate
      thumbnail {
        url
      }
    }
  }
`;

function NewReleasesConnected(props) {
  const [filter, setFilter] = useState('past');

  const { loading, data } = useQuery(GQL_GET_NEW_RELEASES);
  const tabButtonsConn = (
    <div className="mangaCards-container-selector">
      <Button
        variant={filter === 'past' ? 'light' : 'dark'}
        onClick={() => {
          setFilter('past');
        }}
        className="button"
        label="Récentes"
      />
      <Button
        variant={filter === 'future' ? 'light' : 'dark'}
        onClick={() => {
          setFilter('future');
        }}
        className="button"
        label="À venir"
      />
    </div>
  );
  const releases =
    data?.releases?.map(release => ({
      ...release,
      releaseDate: new Date(release.releaseDate),
    })) || [];
  return (
    <MangaCardsReleases
      loading={loading}
      releases={
        filter === 'past'
          ? releases
              .filter(release => dayjs().isAfter(release.releaseDate))
              .sort((a, b) => b.releaseDate - a.releaseDate)
          : releases
              .filter(release => dayjs().isBefore(release.releaseDate))
              .sort((a, b) => a.releaseDate - b.releaseDate)
      }
      title="Sorties mangas"
      preTitle="Nouveautés"
      tab={tabButtonsConn}
      pill={
        filter === 'past' ? (
          <IndicatorPill backgroundColor="rgb(225, 31, 123)">
            {
              // filter releases that are before today and 4 days ago
              releases.filter(
                release =>
                  dayjs().isAfter(release.releaseDate) &&
                  dayjs()
                    .subtract(4, 'day')
                    .isBefore(release.releaseDate)
              ).length
            }
          </IndicatorPill>
        ) : null
      }
    />
  );
}

export default function NewReleasesConnectedShielded(props) {
  return (
    <CustomErrorBoundary handleError={() => null}>
      <NewReleasesConnected {...props} />
    </CustomErrorBoundary>
  );
}
