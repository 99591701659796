import { Text } from '@mangas-io/design-system';

import './DisclaimerInvited.scss';

const DisclaimerInvited = ({ profile }) => {
  if (profile.info?.status === 'invited' && profile.access === 'PAID')
    return (
      <Text size="small" className="warningText">
        Activer ton code mettra fin à ton offre actuelle
      </Text>
    );
  return null;
};

export default DisclaimerInvited;
