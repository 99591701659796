import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { PrismicText } from '@prismicio/react';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import { PlaceholderShape } from '@mangas-io/design-system';

import { usePartners } from 'hooks';
import { range, trackHandler } from 'utils';

import './PublishersBloc.scss';

const PublishersBloc = () => {
  const partners = usePartners();
  return (
    <div id="LandingAdvantage" className="">
      <div className="section-container">
        <div className="content-wrapper">
          <div className="partners-advantage-container">
            <div className="partners">
              <p className="section-title">
                <span>Nos éditeurs partenaires</span>
                <i className="hr" />
              </p>
              <div className="partner-box">
                <div className="content">
                  <ul className="partners-list">
                    {partners.length === 0 &&
                      range(8).map(key => (
                        <PlaceholderShape
                          style={{
                            width: '100px',
                            height: '84px',
                            borderRadius: '5px',
                            margin: '20px',
                          }}
                          key={key}
                        />
                      ))}
                    {partners
                      .filter(partner => partner.is_publisher)
                      .map((partner, key) => (
                        <li key={key}>
                          <Tooltip
                            interactive
                            arrow
                            enterTouchDelay={50}
                            title={
                              <p>
                                <PrismicText field={partner.description} />
                                <br />
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={partner.url.url}
                                  onClick={e =>
                                    trackHandler(e, 'Publisher Clicked', {
                                      name: partner.name,
                                      slug: partner.slug,
                                    })
                                  }
                                >
                                  Voir le site de l’éditeur{' '}
                                  <Icon path={mdiChevronRight} />
                                </a>
                              </p>
                            }
                          >
                            <img
                              src={partner.img.url}
                              width={partner.img.dimensions.width}
                              height={partner.img.dimensions.height}
                              alt={partner.img.alt}
                            />
                          </Tooltip>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishersBloc;
