import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiAccountCircle,
  mdiChevronRight,
  mdiGift,
  mdiCreditCardClockOutline,
  mdiDiscord,
  mdiLightbulbOn,
  mdiBugOutline,
  mdiHelpCircle,
  mdiLoginVariant,
  mdiBell,
} from '@mdi/js';

import { Text } from '@mangas-io/design-system';

import { FloatingMenu } from 'UI';
import { useAuth, useProfile } from 'hooks';
import { trackHandler, upvoty } from 'utils';

const MainSlide = props => {
  const { navigation, flags } = props;
  const { logout } = useAuth();
  const { profile } = useProfile();

  return (
    <div className={classnames(props.className, 'slide')}>
      <FloatingMenu.Header>Compte</FloatingMenu.Header>
      <FloatingMenu.Body>
        <div
          className="row clickable"
          onClick={() => navigation.push('profile')}
        >
          <span className="settings-icon">
            <Icon path={mdiAccountCircle} />
          </span>
          <Text>Profil</Text>
          <span className="settings-icon">
            <Icon path={mdiChevronRight} />
          </span>
        </div>
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('notifications')}
          >
            <span className="settings-icon">
              <Icon path={mdiBell} />
            </span>
            <Text>Notifications</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('invitation')}
          >
            <span className="settings-icon">
              <Icon path={mdiGift} />
            </span>
            <Text>Parrainage</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('subscription')}
          >
            <span className="settings-icon">
              <Icon path={mdiCreditCardClockOutline} />
            </span>
            <Text>Abonnement</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noProfile && (
          <div
            className="row clickable"
            onClick={() => navigation.push('voucher')}
          >
            <span className="settings-icon">
              <Icon path={mdiGift} />
            </span>
            <Text>Carte cadeau</Text>
            <span className="settings-icon">
              <Icon path={mdiChevronRight} />
            </span>
          </div>
        )}
        {!flags.noPromo && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/975cZgh"
            onClick={e => trackHandler(e, 'Discord Clicked')}
          >
            <span className="settings-icon">
              <Icon path={mdiDiscord} />
            </span>

            <Text>Rejoindre notre discord</Text>
          </a>
        )}
        {!flags.noPromo && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://mangasio.upvoty.com/b/demande-de-fonctionnalite/"
            onClick={e => {
              upvoty.upvotySSO(profile);
              trackHandler(e, 'Feature Request Clicked');
            }}
          >
            <span className="settings-icon">
              <Icon path={mdiLightbulbOn} />
            </span>

            <Text>Proposer une fonctionnalité</Text>
          </a>
        )}{' '}
        {!flags.noPromo && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://mangasio.upvoty.com/b/corrections-et-bugs/"
            onClick={e => {
              upvoty.upvotySSO(profile);
              trackHandler(e, 'Bug Report Clicked');
            }}
          >
            <span className="settings-icon">
              <Icon path={mdiBugOutline} />
            </span>

            <Text>Signaler un bug ou une correction</Text>
          </a>
        )}
        <a
          className="row clickable"
          target="_blank"
          href="https://www.notion.so/Aide-c31616b7c57d4959a367d9bb6be6a4d6"
          onClick={e => trackHandler(e, 'Help Clicked')}
          rel="noopener noreferrer"
        >
          <span className="settings-icon">
            <Icon path={mdiHelpCircle} />
          </span>
          <Text>Aide</Text>
        </a>
        <div className="row clickable" onClick={logout}>
          <span className="settings-icon">
            <Icon path={mdiLoginVariant} />
          </span>
          <Text>Se déconnecter</Text>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default MainSlide;
