import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Input, Button } from '@mangas-io/design-system';

import {
  CaptiveNavbar,
  LandingFaq,
  LandingFeatures,
  LandingHero,
  PublishersBloc,
  LandingPopularManga,
  LandingUsersFeedback,
  MinimalFooter,
} from 'Components';

import { metrics } from 'utils';
import { useAuth, useAppState } from 'hooks';

import '../Landing/LandingPage.scss';
import './ReferralPage.scss';

const errors = {
  INVALID_REFERRAL: 'Code de parrainage invalide',
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (errors[error]) return errors[error];
  return errors.default;
}

export default function ReferralPage() {
  const { code } = useParams();
  const { validateReferral } = useAuth();
  const [currentCode, setCode] = useState(code || '');
  const [disabled, setDisabled] = useState(currentCode.length === 0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [, actions] = useAppState();
  const history = useHistory();
  const navigate = history.push;

  useEffect(() => {
    setDisabled(currentCode.length === 0);
  }, [currentCode]);

  const handleSubmit = async () => {
    setLoading(true);
    const { error, valid } = await validateReferral(currentCode);
    if (error) setError(getErrorMessage(error));
    if (valid) {
      actions.setReferralAction(currentCode);
      navigate('/signup');
    }
    setLoading(false);
  };

  return (
    <div id="Landing" className="ReferralPage">
      <CaptiveNavbar
        cta={
          <Button
            variant="primary"
            label="Activer l’offre"
            loading={loading}
            disabled={disabled}
            onClick={!disabled ? handleSubmit : undefined}
          />
        }
      >
        2 semaines offertes !
      </CaptiveNavbar>
      <header>
        <LandingHero
          tags={[
            'Sans engagement',
            'Pas de publicité',
            `${metrics.readers} utilisateur.ice.s`,
          ]}
          title="2 semaines offertes sur ton abonnement !"
          subtitle={`Accède à des dizaines de milliers de chapitres à lire en illimité sur Mangas.io !`}
        >
          <div className="code-validation-input">
            <Input
              value={currentCode}
              onChange={e => setCode(e.target.value)}
              placeholder="Ton Code de parrainage"
              highlight={error ? 'error' : null}
              helper={error}
            />
            <Button
              label="Activer"
              variant="primary"
              size="large"
              loading={loading}
              disabled={disabled}
              onClick={!disabled ? handleSubmit : undefined}
            />
          </div>
        </LandingHero>
      </header>

      <section id="advantage" className="section">
        <PublishersBloc />
      </section>

      <section id="faq" className="section">
        <LandingFaq
          title="Tout savoir sur l’offre de parrainage"
          page_name="faq_parrainage"
        />
      </section>

      <section id="popularManga" className="section">
        <LandingPopularManga hideCTA />
      </section>

      <section id="features" className="section">
        <LandingFeatures />
      </section>

      <section id="feedback" className="section">
        <LandingUsersFeedback />
      </section>

      <MinimalFooter />
    </div>
  );
}
