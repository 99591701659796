import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiLock,
  mdiContentCopy,
  mdiInformationOutline,
} from '@mdi/js';
import { Link } from 'react-router-dom';
import { Text } from '@mangas-io/design-system';

import { FloatingMenu } from 'UI';
import { dayjs, trackHandler, track } from 'utils';
import { useProfile } from 'hooks';

import './ReferralSlide.scss';

const ReferralSlide = props => {
  const { navigation, className } = props;
  const { profile, loading } = useProfile({ referral: true });
  const [hasCopied, setHasCopied] = useState(false);
  useEffect(() => {
    if (hasCopied) {
      const timeout = setTimeout(() => setHasCopied(false), 1500);
      return () => clearTimeout(timeout);
    }
  }, [hasCopied]);
  if (loading || !profile.referral) return null;
  const { code, completeCount, activationDate } = profile.referral;
  const { info } = profile;

  return (
    <div className={classnames(className, 'slide', 'referral-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Parrainage
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        {!code && (
          <div className="no-code">
            <Icon path={mdiLock} />
            {info.status === 'trialing' && (
              <>
                <Text size="small">Fonctionnalité débloquée dans:</Text>
                <Text size="small" className="countdown">
                  {dayjs(activationDate).toNow(true)}
                </Text>
              </>
            )}
            {profile.isActive &&
              ['APP_STORE', 'PLAY_STORE'].includes(info.vendor) && (
                <Text size="small">
                  Le parrainage n'est pas encore disponible pour les abonnements
                  pris depuis l'application mobile
                </Text>
              )}
            {info.status === 'voucher' && (
              <Text size="small">
                Le parrainage n'est pas encore disponible pour les abonnements
                pris via les cartes cadeaux ou le Pass Culture.
              </Text>
            )}
            {!profile.isActive && (
              <Text size="small">
                Active un <Link to="/abonnement">abonnement mangas.io</Link>{' '}
                pour obtenir ton code de parrainage.
              </Text>
            )}
          </div>
        )}
        {code && (
          <>
            <div className="referral">
              <div className="referral-row">
                <Text size="small">
                  Gagne <strong>2 semaines</strong> d’abonnement pour toi et
                  ton.a filleul.e grâce à ton code de parrainage !
                </Text>
              </div>
              <div className="referral-row horizontal">
                <div>
                  <Text size="small" bold className="title">
                    Code de parrainage
                  </Text>
                  <h1 className="--text code">{code}</h1>
                </div>
                <div className="align-right">
                  <Text size="small" bold className="title">
                    Filleul.e.s
                  </Text>
                  {completeCount}
                </div>
              </div>
            </div>
            <div
              className="row clickable"
              onClick={async () => {
                const url = `${window.location.origin}/parrainage/${code}`;
                await navigator.clipboard.writeText(url);
                setHasCopied(true);

                track('Referral Link Copied', {
                  code: code,
                  url: url,
                });
              }}
            >
              <span className="settings-icon">
                <Icon path={mdiContentCopy} />
              </span>
              <Text>
                {hasCopied ? 'Lien copié !' : 'Copier mon lien de parrainage'}
              </Text>
            </div>
            <div className="row clickable">
              <span className="settings-icon">
                <Icon path={mdiInformationOutline} />
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.notion.so/mangas/Parrainage-Comment-a-marche-8c47af2c60584915bbe761fc4eda4667"
                onClick={e => trackHandler(e, 'Referral Program Clicked')}
              >
                <Text>Comment ça marche ?</Text>
              </a>
            </div>
          </>
        )}
      </FloatingMenu.Body>
    </div>
  );
};

export default ReferralSlide;
