import React, { useState } from 'react';
import { mdiAlertCircleOutline, mdiCheckBold } from '@mdi/js';
import { Input, Button, Disclaimer, Text } from '@mangas-io/design-system';
import { Form, Responsive } from 'UI';
import { useAuth, useMail, useLocalStorage } from 'hooks';
import './Login.scss';

export default function Login(props) {
  const emailPlaceholder = useMail();
  const { disclaimer } = props;
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [voucher] = useLocalStorage('mio-voucher-code', null);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (e) {
      if (e.message === 'BAD_CREDENTIALS')
        setError('Ton identifiant ou mot de passe est incorrect');
      else {
        setError('Une erreur est survenue ಠ_ಠ');
      }
    }
  }
  return (
    <div id="mio-login">
      <form onSubmit={handleSubmit}>
        <Form>
          <Responsive>
            <Text bold>Bienvenue et bonne lecture sur Mangas.io&nbsp;!</Text>
          </Responsive>
          {disclaimer}
          {error && (
            <Disclaimer
              type="danger"
              icon={mdiAlertCircleOutline}
              text={error}
            />
          )}
          {voucher && (
            <Disclaimer
              type="success"
              icon={mdiCheckBold}
              title="Carte cadeau validée !"
              text="Ta carte cadeau sera activée à la connexion"
            />
          )}
          <Input
            onChange={e => setEmail(e.target.value)}
            label="E-mail"
            type="email"
            placeholder={emailPlaceholder}
            required
          />
          <Input
            onChange={e => setPassword(e.target.value)}
            label="Mot de passe"
            type="password"
            placeholder="••••••••••••••••"
            required
          />
          <Button
            type="submit"
            variant="primary"
            label="Se connecter"
            fullWidth
          />
        </Form>
      </form>
    </div>
  );
}
