import React, { useEffect } from 'react';
import { Text, Button } from '@mangas-io/design-system';

import { Form } from 'UI';
import { useCheckout } from 'hooks';

export default function StripeLoader(props) {
  const { error, plan } = props;
  const [checkout] = useCheckout();
  useEffect(() => {
    async function run() {
      await checkout(plan);
    }
    run();
    /*eslint-disable-next-line*/
  }, []);
  return (
    <Form>
      <div className="stripe-loader box text-center text-white">
        <span className="stripe-loader">
          <img
            src="https://cdn.mangas.io/images/stripe-loader.svg"
            width="191"
            height="63"
            alt="stripe"
          />
          <svg
            className="spinner"
            width="49"
            height="49"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="square"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </span>
        <Text size="large">Merci de ta confiance.</Text>
        <Text>
          Redirection vers notre partenaire pour les paiements sécurisés. Si
          aucune page ne s’ouvre,{' '}
          <Button
            variant="text"
            label={<Text bold>clique ici</Text>}
            onClick={() => checkout(plan)}
          />
          .
        </Text>
        {error && (
          <p className="help is-error text-center">
            Une erreur est survenue ಠ_ಠ
          </p>
        )}
      </div>
    </Form>
  );
}
