import React from 'react';
import { Button } from '@mangas-io/design-system';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { ProgressBar } from '@mangas-io/design-system';
import { mdiArrowLeft } from '@mdi/js';
import { useResponsive, useComputedSizes, useAuth } from 'hooks';
import {
  BackLink,
  ResponsiveText,
  Tag,
  responsiveImageBackgroundStyle,
} from 'UI';
import { CustomErrorBoundary, flatMap, dayjs } from 'utils';
import {
  ShareButton,
  FavoriteButtonConnected,
  ReadButtonConnected,
} from 'Components';

import './MangaHeader.scss';

const MangaHeader = ({ manga }) => {
  const { isAuthenticated } = useAuth();
  const { is } = useResponsive();
  const isDesktop = is('lg');
  const isMobile = !is('sm');
  const [headerRef, { width }, sizeLoaded] = useComputedSizes();

  const responsiveBannerUrl =
    isMobile && manga.bannerMobile ? manga.bannerMobile.url : manga.banner.url;
  const responsiveBannerStyle = sizeLoaded
    ? responsiveImageBackgroundStyle({
        src: responsiveBannerUrl,
        width,
        clamp: 100,
      })
    : {};

  const today = dayjs();
  const nextReleasedChapter = flatMap(manga.volumes, volume => volume.chapters)
    .filter(chapter => dayjs(chapter.releaseDate).isAfter(today))
    .sort((a, b) => (dayjs(a.releaseDate).isBefore(b.releaseDate) ? -1 : 1))
    .shift();
  const publishersVO =
    manga.publishers.filter(
      ({ publisher }) => publisher.countryCode !== 'fr'
    ) || [];
  const publishersFR =
    manga.publishers.filter(
      ({ publisher }) => publisher.countryCode === 'fr'
    ) || [];

  const filteredCategories = manga.categories.filter(
    category => category.level < 2
  );

  let subtitleManga = manga.subtitle;
  if (!manga.subtitle) {
    if (manga.isOngoing) {
      if (nextReleasedChapter)
        subtitleManga = `Prochain chapitre le ${dayjs(
          nextReleasedChapter.releaseDate
        ).format('DD/MM/YYYY')}`;
      else subtitleManga = 'Série en cours';
    } else subtitleManga = 'Série terminée';
  }

  const chaptersReadLength = manga.volumes.flatMap(volume =>
    volume.chapters.filter(chapter => chapter.isRead)
  ).length;
  const mangaProgress = Math.min(
    (chaptersReadLength * 100) / manga.chapterCount,
    100
  );

  return (
    <div className="manga-header">
      {!isDesktop && (
        <div className="box-buttons">
          <div className="box-buttons-left">
            <BackLink
              fallback="/"
              render={hasPreviousPage => (
                <Button
                  variant={isMobile ? 'text' : 'secondary'}
                  label={
                    !isMobile ? (hasPreviousPage ? 'Retour' : 'Accueil') : null
                  }
                  size="large"
                  icon={<Icon path={mdiArrowLeft} />}
                />
              )}
            />
          </div>

          <div className="box-buttons-right">
            <FavoriteButtonConnected
              variant={isMobile ? 'text' : 'secondary'}
              mangaId={manga._id}
              size="large"
              label={isMobile ? null : 'Ma liste'}
            />
            <ShareButton
              variant={isMobile ? 'text' : 'secondary'}
              label={isMobile ? null : 'Partager'}
            />
          </div>
        </div>
      )}

      <div
        ref={headerRef}
        className="header-main"
        style={{
          paddingTop: isMobile ? ' 100%' : ' 30%',
          ...responsiveBannerStyle,
        }}
      />
      {isAuthenticated && <ProgressBar value={mangaProgress} />}
      <div
        className="bottom-header"
        style={{ flexDirection: isMobile ? 'column' : 'row' }}
      >
        <div className="columns">
          <div className="leftColumn">
            <ResponsiveText size="small" className="subtitle">
              {subtitleManga}
            </ResponsiveText>
            <div className="title-main">
              <ResponsiveText as="h1" type="display" bold>
                {manga.title}
              </ResponsiveText>
            </div>
            <div>
              <ResponsiveText className="authors" size="small">
                Par{' '}
                {manga.authors.map((author, index) => (
                  <Link to={`/author/${author.slug}`} key={author._id}>
                    {author.name}
                    {index < manga.authors.length - 1 && ', '}
                  </Link>
                ))}
              </ResponsiveText>
            </div>
          </div>
          <div className="rightColumn">
            <div className="categories">
              {filteredCategories.map(cat => (
                <Tag
                  className="tags"
                  isFavorite={cat.isFavorite}
                  slug={cat.slug}
                  key={cat._id}
                  size={isMobile ? 'small' : 'medium'}
                >
                  {cat.label}
                </Tag>
              ))}
            </div>
            {!isMobile && (
              <>
                {publishersFR.length > 0 && (
                  <ResponsiveText className="publishers" size="small">
                    Publication française{' '}
                    {publishersFR.map((publisher, index) => (
                      <span className="publisher" key={publisher.publisher._id}>
                        <Link to={`/publisher/${publisher.publisher.slug}`}>
                          {publisher.publisher.name}
                          {index < publishersFR.length - 1 && ', '}
                        </Link>
                      </span>
                    ))}
                  </ResponsiveText>
                )}
                {publishersVO.length > 0 && (
                  <ResponsiveText className="publishers" size="small">
                    Publication originale{' '}
                    {publishersVO.map((publisher, index) => (
                      <span className="publisher" key={publisher.publisher._id}>
                        <Link to={`/publisher/${publisher.publisher.slug}`}>
                          {publisher.publisher.name}
                          {index < publishersVO.length - 1 && ', '}
                        </Link>
                      </span>
                    ))}
                  </ResponsiveText>
                )}
              </>
            )}
          </div>
        </div>

        <div className="actions">
          <ReadButtonConnected
            manga={manga}
            size="large"
            fullWidth={isMobile}
          />
          {isDesktop && (
            <>
              <FavoriteButtonConnected mangaId={manga._id} size="large" />
              <ShareButton size="large" label="Partager" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function MangaHeaderError() {
  return null;
}
export default function MangaHeaderWrapper(props) {
  return (
    <CustomErrorBoundary handleError={<MangaHeaderError />}>
      <MangaHeader {...props} />
    </CustomErrorBoundary>
  );
}
