import { Text } from '@mangas-io/design-system';

import { MangaLine } from 'Components';
import { CustomErrorBoundary } from 'utils';
import { useRecommend } from 'hooks';
import './SimilarMangasLine.scss';

function SimilarMangasLine({ manga, withTitle }) {
  const { recommendations, loading } = useRecommend(manga._id);
  if (recommendations.length === 0) return null;
  return (
    <>
      {withTitle && (
        <>
          <Text className="similarMangasTitle">Tu aimes {manga.title}</Text>
          <Text bold size="extra-large" className="similarMangasSubtitle">
            Découvre des titres similaires
          </Text>
        </>
      )}
      <MangaLine
        loading={loading}
        tileSize={'small'}
        mangas={recommendations.map(reco => ({
          ...reco,
          thumbnail: { url: reco.thumbnail },
        }))}
      />
    </>
  );
}
export default function SimilarMangasLineShielded(props) {
  return (
    <CustomErrorBoundary>
      <SimilarMangasLine {...props} />
    </CustomErrorBoundary>
  );
}
