import React from 'react';
import { Bloc, Text } from '@mangas-io/design-system';
import { useQuery, useResponsive } from 'hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import {
  PublisherHeader,
  Footer,
  NotFound,
  SEO,
  MangaGrid,
  LinkedContent,
} from 'Components';
import { ResponsiveColumns, Information, SocialIcon, Page } from 'UI';
import { useLinkedContent, useFlags } from 'hooks';
import { trackHandler, trackLink } from 'utils';
import './PublisherPage.scss';
const { Row } = Information;

const PUBLISHER_FRAGMENT = `
    _id
  slug
  description
  name
  subtitle
  website
  banner {
    url
  }
  bannerMobile: banner(target: MOBILE) {
    url
  }
  socialLinks {
    target
    url
  }
  logo {
    url
  }
  countryCode
  mangas {
    _id
    slug
    chapterCount
    title
    thumbnail {
      url
    }
    categories {
      label
      level
    }
  }  
`;

const GQL_GET_PUBLISHER_BY_SLUG = gql`
  query GetPublisher($slug: String) {
    publisher(slug: $slug) {
      ${PUBLISHER_FRAGMENT}
    }
  }
`;

const GQL_GET_PUBLISHER_BY_ID = gql`
  query GetPublisher($id: ID) {
    publisher(id: $id) {
      ${PUBLISHER_FRAGMENT}
    }
  }
`;

export default function PublisherPage(props) {
  const { is } = useResponsive();
  const isDesktop = is('lg');
  const { slug } = useParams();
  const { flag: noPromoFlag } = useFlags('NO_PROMO');

  // RETROCOMPATIBILITY : work both with id and slug
  // CAUTION : if the slug matches the regex, it will be treated like an id, which may cause false positives
  const slugIsAnId = /^[0-9a-fA-F]{24}$/.test(slug);
  const { data, loading } = useQuery(
    slugIsAnId ? GQL_GET_PUBLISHER_BY_ID : GQL_GET_PUBLISHER_BY_SLUG,
    {
      variables: slugIsAnId ? { id: slug } : { slug },
    }
  );

  const { publisher } = data || {};

  if (!publisher && !loading) return <NotFound />;
  if (loading) return <div id="PublisherPage" />;

  return (
    <>
      <Page id="PublisherPage">
        <SEO
          title={`Retrouve les titres de ${publisher.name} à l'abonnement sur Mangas.io`}
          description={`Toutes les informations sur la maison d'édition ${publisher.name} sont sur Mangas.io ! Retrouve également la liste des titres ${publisher.name} disponibles à l'abonnement sur Mangas.io`}
        />

        <PublisherHeader publisher={publisher} />
        <ResponsiveColumns oneColumn={!isDesktop || noPromoFlag}>
          {(publisher.description ||
            publisher.website ||
            (publisher.socialLinks !== null &&
              publisher.socialLinks.length > 0)) && (
            <Bloc
              className="info-bloc"
              renderPosition="main"
              title={`${publisher.name} en quelques mots`}
            >
              <Information>
                {publisher.description && (
                  <Row className="descriptionPublisher" title="Histoire">
                    {publisher.description}
                  </Row>
                )}
                {publisher.website && (
                  <Row title={`Site web de ${publisher.name}`}>
                    <a
                      href={publisher.website}
                      onClick={e =>
                        trackHandler(e, 'Publisher Clicked', {
                          name: publisher.name,
                          slug: publisher.slug,
                        })
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="externalLink">
                        {`Voir le site de ${publisher.name} `}
                        <Icon path={mdiOpenInNew} />
                      </div>
                    </a>
                  </Row>
                )}
                {publisher.socialLinks !== null &&
                  publisher.socialLinks.length > 0 && (
                    <Row
                      title={`Retrouve ${publisher.name} sur ces réseaux sociaux :`}
                    >
                      {publisher.socialLinks.map(elem => (
                        <SocialIcon
                          key={elem.url}
                          type={elem.target}
                          link={trackLink(elem.url, 'Publisher Clicked', {
                            name: publisher.name,
                            slug: publisher.slug,
                          })}
                        />
                      ))}
                    </Row>
                  )}
              </Information>
            </Bloc>
          )}
          {!noPromoFlag && (
            <SidebarContent renderPosition="sidebar" publisher={publisher} />
          )}
        </ResponsiveColumns>
        <div id="mangaListAnchor" />
        {publisher.mangas?.length > 0 && (
          <>
            <Text
              as="h2"
              className="blocTitle"
              type="display"
              size="small"
              bold
            >
              {`Titres de ${publisher.name}`}
            </Text>
            <MangaGrid mangas={publisher.mangas} loading={loading} />
          </>
        )}
      </Page>
      <Footer />
    </>
  );
}

function SidebarContent({ publisher }) {
  const linkedContentData = useLinkedContent(publisher?.name);

  if (linkedContentData.length === 0) return null;
  return (
    <LinkedContent
      documents={linkedContentData}
      wrapperElement={props => (
        <Bloc
          className="contenus-lies-bloc"
          title={
            linkedContentData.length === 1
              ? 'Récemment publié'
              : 'Récemment publiés'
          }
          {...props}
        />
      )}
    />
  );
}
