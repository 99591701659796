import React from 'react';
import { Link } from 'react-router-dom';
import { MangaTile } from '@mangas-io/design-system';
import FavoriteButtonConnected from 'Components/FavoriteButton/FavoriteButtonConnected';
import { useResponsive } from 'hooks';
import { getResizedUrl, withPixelDensity } from 'utils';
import './MangaGrid.scss';

const MangaGrid = (props, ref) => {
  const { is } = useResponsive();
  const isDesktop = is('lg');

  const { mangas, loading, loadingCards = 10 } = props;
  return (
    <div className="MangaGrid" ref={ref}>
      {loading &&
        Array.from({ length: loadingCards }, (_, index) => (
          <MangaTile
            key={index}
            loading={true}
            size={isDesktop ? 'medium' : 'small'}
          />
        ))}

      {!loading &&
        mangas.map(manga => (
          <Link key={manga._id} to={`/lire/${manga.slug}`}>
            <MangaTile
              key={manga.slug}
              picture={getResizedUrl(manga.thumbnail.url, {
                width: withPixelDensity(isDesktop ? 180 : 140),
              })}
              size={isDesktop ? 'medium' : 'small'}
              title={manga.title}
              subtitle={`${manga.categories
                .filter(cat => cat.level < 2)
                .slice(0, 2)
                .sort((a, b) => a.level - b.level)
                .map(tag => tag.label)
                .join(' · ')}`}
            >
              <FavoriteButtonConnected
                mangaId={manga._id}
                size="small"
                noLabel
              />
            </MangaTile>
          </Link>
        ))}
    </div>
  );
};

export default React.forwardRef(MangaGrid);
