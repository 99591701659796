import React from 'react';
import { Text } from '@mangas-io/design-system';
import { CustomErrorBoundary, trackHandler } from 'utils';
import { usePartners } from 'hooks';
import './LandingPartners.scss';

function LandingPartners() {
  const partners = usePartners();
  return (
    <div id="LandingPartners">
      <Text type="display" bold size="small" className="title">
        Partenaires commerciaux
      </Text>
      <div className="partner-box">
        <div className="content">
          <div className="partners-list">
            {partners
              .filter(partner => !partner.is_publisher)
              .map(({ name, slug, img, url }) => (
                <li key={img.alt}>
                  <a
                    className="link-item"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={url.url}
                    onClick={e =>
                      trackHandler(e, 'Partner Clicked', {
                        name,
                        slug,
                      })
                    }
                  >
                    <img
                      src={img.url}
                      width={100}
                      height="auto"
                      alt={img.alt}
                    />
                  </a>
                </li>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function LandingPartnersWrapper(props) {
  return (
    <CustomErrorBoundary handleError={() => null}>
      <LandingPartners {...props} />
    </CustomErrorBoundary>
  );
}

export default LandingPartnersWrapper;
