import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { Text, Checkbox } from '@mangas-io/design-system';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';
import './NotificationSlide.scss';

const NotificationSlide = props => {
  const { navigation, className } = props;

  const { profile, updateMe } = useProfile();

  const updateNotifications = async newValue => {
    await updateMe({
      variables: {
        input: {
          notifications: {
            mail: newValue,
            push: profile.notifications?.push || false,
          },
        },
      },
    });
  };

  return (
    <div
      className={classnames(className, 'slide', 'select-notifications-slide')}
    >
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Mes notifications
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="form">
          <Checkbox
            id="newsletter"
            checked={profile.notifications?.mail}
            onChange={({ checked }) => {
              updateNotifications(checked);
            }}
          >
            <Text>J'accepte de recevoir des notifications par mail</Text>
          </Checkbox>
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

export default NotificationSlide;
