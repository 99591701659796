import React from 'react';
import { useHistory } from 'react-router-dom';
import { Disclaimer, Button } from '@mangas-io/design-system';
import { useResponsive, useFlags } from 'hooks';

import './NudgeBanner.scss';

function SignupBanner({ currentStatus }) {
  const history = useHistory();
  const { is } = useResponsive();
  const isMobile = !is('md');
  const config = {
    text: {
      INVITED:
        'Tu bénéficies d’une invitation gratuite pour découvrir Mangas.io. Cela peut restreindre l’accès au catalogue. Si tu souhaites accéder à l’intégralité, tu peux souscrire à une offre d’abonnement qui remplacera ton offre actuelle.',
    },
  };
  const text =
    config.text?.[currentStatus] ||
    'Profite de 7 jours gratuits pour débloquer l’intégralité du catalogue et de nos fonctionnalités !';
  return (
    <Disclaimer
      type="signup"
      text={text}
      size={isMobile ? 'small' : 'large'}
      button={
        <Button
          label="Activer mon essai gratuit"
          onClick={() =>
            history.push(currentStatus === 'GUEST' ? '/signup' : '/abonnement')
          }
        />
      }
    />
  );
}

function NudgeBanner(props) {
  const { flag: disablePromo, loading } = useFlags('NO_PROMO', true);

  if (disablePromo || loading) return null;
  return (
    <div className="nudge-banner">
      <SignupBanner {...props} />
    </div>
  );
}

export default NudgeBanner;
