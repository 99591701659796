import React from 'react';
import { useResponsive } from 'hooks';
import { Button, Text } from '@mangas-io/design-system';
import { PublicNavbar } from 'Components';
import { track } from 'utils';
import './ErrorPage.scss';

function getErrorMessage(code = null) {
  switch (code) {
    case 'NETWORK':
      return 'Problème réseau, es-tu connecté.e à internet ? ';
    case 'UNAUTHORIZED':
      return 'Droits insuffisants pour effectuer cette action';
    default:
      return (
        <>
          Notre équipe est peut-être sur le coup, patiente quelques instants
          puis réessaye. Si l’erreur persiste, contacte-nous.
        </>
      );
  }
}

export default function ErrorPage(props) {
  const { code } = props;
  const { is } = useResponsive();
  const isDesktop = !is('lg');

  return (
    <div id="errorPage">
      <PublicNavbar />
      <div className="errorFlex">
        <div id="ErrorContainer">
          {isDesktop ? (
            <div className="cookie404 sm">
              <img
                alt="cookie error sm"
                src="https://cdn.mangas.io/landing/coffee-question-mark.png"
                width={100}
              />
            </div>
          ) : null}

          <Text className="marginBottom" type="display" size="large">
            Oups ! (T•T)
          </Text>
          <Text className="marginBottom" type="display" size="medium">
            Une erreur est survenue
          </Text>

          <Text className="marginBottom" type="text" size="large">
            {getErrorMessage(code)}
          </Text>

          <div className="actions">
            <Button
              label="Réessayer"
              variant="primary"
              onClick={() => document.location.reload(true)}
            />
            <Button
              label="Nous contacter"
              secondary
              onClick={async () => {
                track('Contact Clicked', {
                  url: 'mailto:hello@mangas.io',
                  type: 'contact',
                });
                window.open('mailto:hello@mangas.io');
              }}
            />
          </div>
        </div>
        {!isDesktop ? (
          <div className="cookie404">
            <img
              alt="cookie error"
              src="https://cdn.mangas.io/landing/coffee-question-mark.png"
              width={404}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
