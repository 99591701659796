import React from 'react';
import { CarrouselWrapper } from 'Components';
import { InlineCard, CornerTag, Text } from '@mangas-io/design-system';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { range, getResizedUrl, withPixelDensity } from 'utils';

import { ProgressBar } from 'UI';

import './MangaCards.scss';

function toChunks(input, chunkSize) {
  let chunks = [];
  for (let i = 0; i < input.length; i += chunkSize) {
    chunks.push(input.slice(i, i + chunkSize));
  }
  return chunks;
}

function NewReleaseCornerTag({ release }) {
  if (release.chapter.isRead) return null;

  const fourDaysAgo = dayjs().subtract(4, 'day');
  const chapterReleaseDate = dayjs(release.chapter.releaseDate);

  if (chapterReleaseDate.isBefore(fourDaysAgo)) return null;

  return <CornerTag variant="primary">Nouveau chapitre</CornerTag>;
}

const Progress = ({ release }) => {
  return (
    <div className="progressBar">
      <ProgressBar
        completion={Math.ceil(
          ((release.page + 1) * 100) / release.chapter.pageCount
        )}
      />
      <Text size="small" className="progressBar-number">
        {release.page + 1}/{release.chapter.pageCount}
      </Text>
    </div>
  );
};

function MangaCardsHistory({ loading, releases, tab, title, pill, preTitle }) {
  if (loading)
    return (
      <CarrouselWrapper
        preTitle={preTitle}
        title={title}
        loading={true}
        tab={tab}
        className="mangaCards-container"
      >
        {range(3).map(key => (
          <div className="pair" key={key}>
            {range(2).map(key2 => (
              <InlineCard className="mangaCard" key={key2} loading={true} />
            ))}
          </div>
        ))}
      </CarrouselWrapper>
    );

  const releasesPairs = toChunks(releases, 2);
  return (
    <CarrouselWrapper
      title={title}
      withShadow
      pill={pill}
      tab={tab}
      preTitle={preTitle}
      className="mangaCards-container"
    >
      {releasesPairs.map((pair, key) => (
        <div className="pair" key={key}>
          {pair.map(release => {
            const manga = release.manga;

            return (
              <Link
                to={`/lire/${manga.slug}/${
                  release.chapter.number
                }/${release.page + 1}`}
                key={manga._id}
              >
                <InlineCard
                  className="mangaCard"
                  title={manga.title}
                  picture={getResizedUrl(manga.thumbnail.url, {
                    width: withPixelDensity(82),
                    height: withPixelDensity(118),
                  })}
                  subtitle={release.chapter.title}
                  cornerTag={<NewReleaseCornerTag release={release} />}
                >
                  <Progress release={release} />
                </InlineCard>
              </Link>
            );
          })}
        </div>
      ))}
    </CarrouselWrapper>
  );
}

export default MangaCardsHistory;
