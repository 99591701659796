import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { Button } from '@mangas-io/design-system';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiMagnify } from '@mdi/js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Logo, MobileLogo, FloatingMenu } from 'UI';
import { Search } from 'Components';

import './Navbar.scss';

const Navbar = props => {
  const { className } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(state => !state);
  const location = useLocation();
  const [displaySearch, setDisplaySearch] = useState(false);

  const options = {
    reserveScrollBarGap: true,
  };

  useEffect(() => {
    if (isMenuOpen) disableBodyScroll(null, options);
    else clearAllBodyScrollLocks();
    return clearAllBodyScrollLocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <header className={classnames('Navbar PublicNavbar', className)}>
      <div className="side">
        <Link to="/">
          <Logo className="logo" alt="Mangas.io" />
          <MobileLogo className="logoMobile" alt="Mangas.io" />
        </Link>
      </div>
      <div className="center">
        <nav
          className={classnames('nav-menu', {
            active: isMenuOpen,
          })}
        >
          <NavLink
            className="menu-item is-mobile"
            activeClassName="active"
            to={`/signup/`}
          >
            M'abonner
          </NavLink>
          <NavLink
            className="menu-item"
            activeClassName="active"
            to={`/manga-en-ligne/`}
          >
            Nos mangas
          </NavLink>
          <NavLink
            className="menu-item"
            activeClassName="active"
            to={`/tarifs/`}
          >
            Tarifs
          </NavLink>
          <NavLink className="menu-item" activeClassName="active" to={`/blog/`}>
            Blog
          </NavLink>

          <NavLink
            className="menu-item is-mobile"
            activeClassName="active"
            to="/carte-cadeau"
          >
            Carte cadeau
          </NavLink>
        </nav>
        <span
          onClick={toggleMenu}
          className={classnames('mobile-menu-toggle is-mobile clickable', {
            active: isMenuOpen,
          })}
        >
          <Icon path={mdiChevronDown} />
        </span>
      </div>
      <div className="side right">
        <Link className="menu-item item-gift-link" to="/carte-cadeau">
          <img
            className="gift-icon"
            src="https://cdn.mangas.io/images/mdi_gift_magic-min.svg"
            alt="icon gift"
          />
          <span className="gift-label">Carte cadeau</span>
        </Link>
        <span
          className="icon custom-margin icon-search"
          onClick={() => {
            setDisplaySearch(!displaySearch);
          }}
        >
          <Icon path={mdiMagnify} />
        </span>
        <Link className="menu-button" to={`/login/`}>
          <Button variant="secondary" label="Se connecter" />
        </Link>
        <Link className="menu-button is-hidden-mobile" to={`/signup/`}>
          <Button variant="gold" label="M'abonner" />
        </Link>
      </div>
      <FloatingMenu
        isOpen={displaySearch}
        onClose={() => setDisplaySearch(false)}
      >
        <Search onClose={() => setDisplaySearch(false)} />
      </FloatingMenu>
    </header>
  );
};

export default Navbar;
