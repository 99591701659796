import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js';
import classnames from 'classnames';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { Text, Input, Button, Disclaimer } from '@mangas-io/design-system';

import { OnboardingBackground, Form } from 'UI';
import { PublicRoute } from 'utils';
import { useAuth, useMail } from 'hooks';

import './ResetPassword.scss';

function NewPasswordForm() {
  const { token } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const { setNewPassword } = useAuth();
  const [error, setError] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    if (token && password) {
      try {
        await setNewPassword({ token, password });
        history.push({
          pathname: '/login',
          state: {
            disclaimer: 'PASSWORD_UPDATED',
          },
        });
      } catch (e) {
        if (e.message.includes('password:')) setError('INVALID_PASSWORD');
        else setError(e.message);
      }
    }
  }

  function getErrorMessage(error) {
    console.error({ error });
    if (error === 'INVALID_PASSWORD')
      return 'Ton mot de passe doit contenir au minimum 8 caractères';
    if (error === 'INVALID_TOKEN')
      return 'Ton lien de réinitialisation est invalide ou a expiré';
    return 'Une erreur a eu lieu';
  }

  return (
    <div className="mio-reset-password">
      <form onSubmit={handleSubmit}>
        <Form>
          <Text size="medium" className="subtitleLightGrey">
            Allez, cette fois-ci c’est la bonne ! Choisis ton nouveau mot de
            passe.
          </Text>

          {error && (
            <Disclaimer
              type="danger"
              icon={mdiAlertCircleOutline}
              text={getErrorMessage(error)}
              title="Erreur"
            />
          )}

          <Input
            onChange={e => setPassword(e.target.value)}
            className={classnames('input', {
              'is-error': error && error.includes('INVALID_PASSWORD'),
            })}
            type="password"
            label="Nouveau mot de passe"
            placeholder="••••••••••••••••"
            value={password}
            required
          />

          <Button
            className="btnNewPw"
            label="Confirmer le mot de passe"
            variant="primary"
            fullWidth
            type="submit"
          />
        </Form>
      </form>
    </div>
  );
}

function ResetPasswordFormSuccess() {
  return (
    <div className="mio-reset-password">
      <Form>
        <Icon className="successIcon" path={mdiCheck} />
        <Text type="text" size="large" bold>
          Email envoyé !
        </Text>
        <Text type="text" size="medium" className="greyLightText">
          Tu peux fermer cette fenêtre et consulter ta boîte mail.
        </Text>
      </Form>
    </div>
  );
}

function ResetPasswordForm() {
  const [email, setEmail] = useState();
  const { resetPassword } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const emailPlaceholder = useMail();
  async function handleSubmit(e) {
    e.preventDefault();
    await resetPassword(email);
    setEmailSent(true);
  }
  if (emailSent) return <ResetPasswordFormSuccess />;
  return (
    <div className="mio-reset-password">
      <form onSubmit={handleSubmit}>
        <Form>
          <Text>Réinitialisation de mot de passe</Text>

          <Text size="medium" className="subtitleLightGrey">
            Tu as oublié ton mot de passe ? Ça arrive aux meilleur.e.s d’entre
            nous. Renseigne ton email afin de le réinitialiser.
          </Text>

          <Input
            onChange={e => setEmail(e.target.value)}
            label="E-mail"
            type="email"
            placeholder={emailPlaceholder}
            value={email}
            required
          />

          <Button
            type="submit"
            label="Envoyer l’email de réinitialisation"
            variant="primary"
            fullWidth
          />
        </Form>
      </form>
    </div>
  );
}

export default function ResetPasswordPage() {
  const match = useRouteMatch();
  return (
    <OnboardingBackground>
      <PublicRoute exact path={match.path} component={<ResetPasswordForm />} />
      <PublicRoute
        exact
        path={`${match.path}/:token`}
        component={<NewPasswordForm />}
      />
    </OnboardingBackground>
  );
}
