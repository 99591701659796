import React from 'react';
import { Text } from '@mangas-io/design-system';
import { Banner } from 'UI';
import { mdiInformationOutline } from '@mdi/js';
import { dayjs } from 'utils';

function BreakingNews(props) {
  const now = new dayjs();
  const toggleDate = new dayjs('2021-12-18T14:15:00.000Z'); // temps ISO UTC : une heure de moins que l'heure française actuelle
  const toggled = now.isAfter(toggleDate);
  if (process.env.REACT_APP_DISABLE_NEWS) {
    // fail safe : Définir la variable d'env REACT_APP_DISABLE_NEWS
    // à true pour complètement désactiver les news
    return null;
  }
  return (
    <div className="landing-banner-wrapper">
      {toggled && (
        <Banner
          iconOutline
          kana
          icon={mdiInformationOutline}
          link="/blog/kana"
          linkLabel="En savoir plus"
          {...props}
        >
          <b>BREAKING NEWS :</b> Les éditions Kana rejoignent Mangas.io dès le
          27 janvier 2022&nbsp;!
        </Banner>
      )}
      {!toggled && (
        <Banner
          secondary
          icon={mdiInformationOutline}
          iconOutline
          link="/blog/festival-pop-asia-matsuri"
          linkLabel="En savoir plus"
          {...props}
        >
          <Text size="medium">
            <b>BREAKING NEWS :</b> Grosse annonce de Mangas.io durant le Pop
            Asia Matsuri les 18 et 19 décembre 2021&nbsp;!
          </Text>
        </Banner>
      )}
    </div>
  );
}

export default BreakingNews;
