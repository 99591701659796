import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { Text } from '@mangas-io/design-system';

import './VoucherSuccessSlide.scss';

export default function VoucherSuccessSlide(props) {
  const { className, navigation } = props;
  return (
    <div className={classnames(className, 'slide')}>
      <div
        className="row header withBorder clickable"
        onClick={() => {
          navigation.pop();
        }}
      >
        <span className="icon">
          <Icon path={mdiArrowLeft} />
        </span>
        <h1 className="--subtitle">Code validé - Merci !</h1>
      </div>
      <div className="floating-wrapper">
        <div className="voucherSuccessSlide">
          <img
            className="coffee-success"
            src="https://cdn.mangas.io/images/coffee/voucher-success-min.png"
            alt="Félicitation par la mascotte Mangas.io"
          />
          <Text>
            Ta carte cadeau a été validée. Merci de la part de toute l’équipe{' '}
            <span role="img" aria-label="emoji heart">
              ❤️
            </span>
          </Text>
        </div>
      </div>
    </div>
  );
}
