import React from 'react';
import { ContinueReadingBlock } from 'Components';
import { Text } from '@mangas-io/design-system';
import { useHistory } from 'hooks';
import { repriseFormatter } from 'utils';
import './ContinueReadingBlockConnected.scss';

function ContinueReadingBlockConnected({
  manga,
  wrapperElement: Wrapper = <></>,
  ...props
}) {
  const { loading, data: history } = useHistory(manga._id);
  if (loading || !history || !history.me) return null;
  const latestRead = history.me.history[0];
  if (!latestRead) return null;
  const [formattedHistory] = repriseFormatter([latestRead]);

  const isCompleted =
    formattedHistory.chapterIsFinished && !formattedHistory.hasNextChapter;

  if (isCompleted) {
    return (
      <Wrapper {...props}>
        <div className="isCompleted">
          <div className="isCompleted-bg sparkle"></div>
          <div className="isCompleted-bg second-sparkle"></div>
          <div className="isCompleted-bg third-sparkle"></div>

          <div className="content">
            <img
              className="coffee-reading"
              src="https://cdn.mangas.io/images/coffee/reading.png"
              alt="Tu as lu tous les chapitres disponibles !"
              width={100}
              height={100}
            />
            <Text size="small"> Tu as lu tous les chapitres disponibles !</Text>
          </div>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper {...props}>
      <div className="history">
        <ContinueReadingBlock history={formattedHistory} />
      </div>
    </Wrapper>
  );
}

export default ContinueReadingBlockConnected;
