import React from 'react';
import { Tag } from '@mangas-io/design-system';
import { Link } from 'react-router-dom';
import './Tag.scss';

export default function UiTag({ slug, isFavorite = false, ...props }) {
  return slug ? (
    <Link
      className="linkable"
      to={isFavorite ? `/manga-en-ligne/${slug}` : `/categorie/${slug}`}
    >
      <Tag {...props} />
    </Link>
  ) : (
    <Tag {...props} />
  );
}
