import React from 'react';
import { Button } from '@mangas-io/design-system';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useResponsive } from 'hooks';
import { BackLink, ResponsiveText } from 'UI';
import { CustomErrorBoundary } from 'utils';
import { ShareButton } from 'Components';

import './CollectionHeader.scss';

const CollectionHeader = ({ collection }) => {
  const { is } = useResponsive();
  const isDesktop = is('lg');
  const isMobile = !is('sm');

  return (
    <div className="collection-header">
      {!isDesktop && (
        <div className="box-buttons">
          <div className="box-buttons-left">
            <BackLink
              fallback="/"
              render={hasPreviousPage => (
                <Button
                  variant={isMobile ? 'text' : 'secondary'}
                  label={
                    !isMobile ? (hasPreviousPage ? 'Retour' : 'Accueil') : null
                  }
                  size="medium"
                  icon={<Icon path={mdiArrowLeft} />}
                />
              )}
            />
          </div>

          <div className="box-buttons-right">
            <ShareButton
              variant={isMobile ? 'text' : 'secondary'}
              label={isMobile ? null : 'Partager'}
            />
          </div>
        </div>
      )}
      <div
        className="header-main"
        style={{
          backgroundImage: `url(${collection.image.url})`,
          paddingTop: '30%',
        }}
      ></div>
      <div className="bottom-header">
        <div className="columns">
          <div className="leftColumn">
            <div className="title-main">
              <ResponsiveText as="h1" type="display" bold>
                {collection.title}
              </ResponsiveText>
              {collection.description && (
                <ResponsiveText size="small" className="description">
                  {collection.description}
                </ResponsiveText>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function CollectionHeaderError() {
  return null;
}
export default function CollectionHeaderWrapper(props) {
  return (
    <CustomErrorBoundary handleError={<CollectionHeaderError />}>
      <CollectionHeader {...props} />
    </CustomErrorBoundary>
  );
}
