import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiCog, mdiLockOpen, mdiEmail, mdiPhone } from '@mdi/js';
import { Button, Text } from '@mangas-io/design-system';
import { Link } from 'react-router-dom';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';
import { dayjs } from 'utils';

import './SubscriptionSlide.scss';

function SubscriptionSlide(props) {
  const { navigation, className } = props;
  const { profile, loading } = useProfile();

  if (loading) return null;
  return (
    <div className={classnames(className, 'slide', 'subscription-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Abonnement
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <CurrentStatus profile={profile} />
        <CurrentVendor profile={profile} />
        <CurrentPeriod profile={profile} />
        <CurrentPrice profile={profile} />
        <CurrentEndDate profile={profile} />
        <SubscriptionManagement profile={profile} navigation={navigation} />
        <SubscriptionNudge profile={profile} />
        <DisclaimerMobileSubscription profile={profile} />
        <DisclaimerDekupleSubscription profile={profile} />
        <DisclaimerGuestSubscription profile={profile} />
      </FloatingMenu.Body>
    </div>
  );
}

function Line({ label, children }) {
  return (
    <div className="line">
      <span className="label">{label}</span>
      {children}
    </div>
  );
}

function CurrentStatus({ profile }) {
  const config = {
    active: {
      label: 'Lecture illimitée',
      decoratorClass: 'gradient-gold',
    },
    trialing: {
      label: "En période d'essai",
      decoratorClass: 'gradient-gold',
    },
    visitor: {
      label: 'Découverte',
      decoratorClass: 'gradient-green',
    },
    error: {
      label: 'Erreur',
      decoratorClass: 'gradient-red',
    },
    guest: {
      label: 'Découverte MAX',
      decoratorClass: 'gradient-blue',
    },
    canceling: {
      label: 'Annulation en cours',
      decoratorClass: 'dark-red',
    },
  };

  let status = 'visitor';

  if (profile.access === 'PAID') {
    if (profile.info.status === 'error') status = 'error';
    else if (profile.info.status === 'trialing') status = 'trialing';
    else if (profile.info.status === 'canceling') status = 'canceling';
    else if (profile.info.status === 'invited' && profile.isActive)
      status = 'guest';
    else status = 'active';
  }

  const { label, decoratorClass } = config[status];

  return (
    <Line label="Statut">
      <span className={`currentStatusPill ${decoratorClass}`}>{label}</span>
    </Line>
  );
}

function DisclaimerGuestSubscription({ profile }) {
  if (profile.access !== 'PAID' || profile.info.status !== 'invited')
    return null;
  return (
    <div className="subscription-cta-container">
      <Text size="small">
        Tu bénéficies d’une invitation gratuite pour découvrir Mangas.io. Cela
        peut restreindre l’accès au catalogue. Si tu souhaites accéder à
        l’intégralité, tu peux souscrire à une offre d’abonnement qui remplacera
        ton offre actuelle.
      </Text>
      <Link to="/abonnement" style={{ width: '100%', maxWidth: '300px' }}>
        <Button variant="gold" label="Je m'abonne" fullWidth />
      </Link>
    </div>
  );
}

function CurrentVendor({ profile }) {
  if (profile.access !== 'PAID') return null;
  let type = 'Carte bleue';
  if (['APP_STORE', 'PLAY_STORE'].includes(profile.info.vendor))
    type = 'Mobile';
  else if (profile.info.plan?.match(/pass culture/i)) type = 'Pass culture';
  else if (profile.info.vendor === 'DEKUPLE') type = 'À vos kids';
  else if (profile.info.status === 'voucher') type = 'Carte cadeau';
  else if (profile.info.status === 'invited') type = 'Invitation';

  return <Line label="Type d'abonnement">{type}</Line>;
}

function CurrentPeriod({ profile }) {
  if (profile.access !== 'PAID') return null;
  if (!['MONTH', 'YEAR', 'SEMESTER'].includes(profile.info.subscriptionType))
    return null;

  const config = {
    MONTH: 'mensuel',
    YEAR: 'annuel',
    SEMESTER: 'semestriel',
  };

  return (
    <Line label="Périodicité">
      Abonnement {config[profile.info.subscriptionType]}
    </Line>
  );
}

function CurrentPrice({ profile }) {
  if (profile.access !== 'PAID') return null;
  const { price, discount, vendor } = profile.info;
  if (!['STRIPE', 'APP_STORE', 'PLAY_STORE'].includes(vendor)) return null;

  return (
    <Line label="Prix">
      <div>
        {discount !== 0 && <del>{(price / 100).toFixed(2)} </del>}
        {((price - discount) / 100).toFixed(2)} €
      </div>
    </Line>
  );
}

function CurrentEndDate({ profile }) {
  if (profile.access !== 'PAID') return null;

  let label = 'Date de renouvellement';
  if (profile.info.status === 'canceling') label = "Date d'annulation";
  else if (['voucher', 'invited'].includes(profile.info.status))
    label = "Date d'expiration";
  return (
    <Line label={label}>
      {dayjs(profile.info.end_date).format('DD/MM/YYYY')}
      {profile.info.status === 'extended' && ' 🎁'}
    </Line>
  );
}

function SubscriptionManagement({ profile, navigation }) {
  if (profile.info.vendor !== 'STRIPE' || profile.info.status === 'canceled')
    return null;

  return (
    <div
      className="row clickable"
      onClick={() => navigation.push('subscription_management')}
    >
      <span className="settings-icon">
        <Icon path={mdiCog} />
      </span>
      <Text>Gérer mon abonnement</Text>
    </div>
  );
}

function SubscriptionNudge({ profile }) {
  if (profile.access === 'PAID') return null;

  return (
    <Link
      className="row clickable"
      to={{ pathname: 'abonnement', state: { hasDiscoveryPlan: false } }}
    >
      <span className="settings-icon">
        <Icon path={mdiLockOpen} />
      </span>
      <Text>Débloquer la lecture illimitée</Text>
    </Link>
  );
}

function DisclaimerMobileSubscription({ profile }) {
  if (
    !profile.access === 'PAID' ||
    !['APP_STORE', 'PLAY_STORE'].includes(profile.info.vendor)
  )
    return null;
  return (
    <div className="info">
      <Text size="small">
        Tu as souscrit à un abonnement via l'application mobile.
        <br />
        Pour gérer ton abonnement, rendez-vous dans les paramètres de ton compte{' '}
        {profile.info.vendor === 'APP_STORE' ? 'Apple' : 'Android'}.
      </Text>
    </div>
  );
}

function DisclaimerDekupleSubscription({ profile }) {
  if (!profile.access === 'PAID' || profile.info.vendor !== 'DEKUPLE')
    return null;
  return (
    <div className="info">
      <Text size="small">
        Tu as souscrit à un abonnement via <b>A vos Kids.</b>
        <br />
        Pour gérer ton abonnement ou obtenir de l'aide, contacte :
      </Text>

      <div className="row ">
        <span className="settings-icon">
          <Icon path={mdiEmail} />
        </span>
        <Text>contact@avoskids.com</Text>
      </div>

      <div className="row ">
        <span className="settings-icon">
          <Icon path={mdiPhone} />
        </span>
        <Text>01 73 60 15 05 </Text>
      </div>
    </div>
  );
}

export default SubscriptionSlide;
