import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import useReaderSettings from './useReaderSettings';

const GQL_GET_PAGE = gql`
  query getPageById($id: ID!, $quality: PageType) {
    page(id: $id) {
      _id
      isDoublePage
      image(type: $quality) {
        url
      }
    }
  }
`;

export default function usePage(id) {
  const [{ quality }] = useReaderSettings();
  const [load, { data = { page: { image: {} } }, error }] = useLazyQuery(
    GQL_GET_PAGE,
    {
      variables: {
        id: id,
        quality: quality.toUpperCase(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  if (error) throw error;
  return [load, data.page];
}
