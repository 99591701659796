import React from 'react';
import { PrismicText, useSinglePrismicDocument } from '@prismicio/react';
import { Carrousel } from '@mangas-io/design-system';
import { range, CustomErrorBoundary } from 'utils';
import { ResponsiveText } from 'UI';

import './LandingUsersFeedback.scss';

const FeedbackCard = props => {
  const { color, username, text, avatar, icon, info } = props;

  return (
    <div
      className="user-feedback"
      style={{ color: color, backgroundColor: color }}
    >
      <p className="username">{username}</p>
      <span className="info">{info}</span>
      <hr className="separator" />
      <p>{text}</p>
      <div className="user-avatar" style={{ borderColor: color }}>
        <img
          src={avatar}
          width="64"
          height="64"
          alt="User avatar"
          className=""
        />
      </div>
      <div className="user-feedback-icon" style={{ color: color }}>
        <img src={icon} width="40" height="40" alt="" className="" />
      </div>
    </div>
  );
};

function LandingUsersFeedback({ title }) {
  const [document, { state }] = useSinglePrismicDocument('reviews');

  const loading = state !== 'loaded';
  const reviews = !loading ? document.data.reviews : [];

  const colors = ['#664AC8', '#AD1055', '#D4294C', '#2B7FC9'];

  return (
    <div id="LandingFeedback">
      <div
        className="feedback-bg-1 rellax"
        data-rellax-speed="-2"
        data-rellax-percentage="0.5"
      ></div>
      <div
        className="feedback-bg-2 rellax"
        data-rellax-speed="-4"
        data-rellax-percentage="0.5"
      ></div>

      <div className="section-container">
        <div className="content-wrapper">
          {title || (
            <ResponsiveText type="display" size="medium" bold>
              Les avis de la communauté manga
            </ResponsiveText>
          )}
        </div>
      </div>

      <Carrousel withControls>
        {loading &&
          range(5).map(index => (
            <FeedbackCard key={index} color={colors[index % colors.length]} />
          ))}
        {reviews.map((review, index) => (
          <FeedbackCard
            key={index}
            color={colors[index % colors.length]}
            username={review.name}
            info={review.subtitle}
            text={<PrismicText field={review.review} />}
            avatar={review.avatar.url}
            icon={review.emoji.url}
          />
        ))}
      </Carrousel>
    </div>
  );
}

function LandingUsersFeedbackWrapper(props) {
  return (
    <CustomErrorBoundary handleError={() => null}>
      <LandingUsersFeedback {...props} />
    </CustomErrorBoundary>
  );
}

export default LandingUsersFeedbackWrapper;
