import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Text, Disclaimer } from '@mangas-io/design-system';
import { mdiAlertCircleOutline } from '@mdi/js';
import { Login } from 'Components';
import { OnboardingBackground, FormFooter } from 'UI';

function getDisclaimer(state = {}) {
  if (state.disclaimer === 'PASSWORD_UPDATED')
    return (
      <Disclaimer
        className="disclaimerSuccess"
        type="success"
        icon={mdiAlertCircleOutline}
        text="Tu peux maintenant te connecter"
        title="Mot de passe mis à jour"
      />
    );
  return null;
}

export default function LoginPage() {
  const location = useLocation();
  return (
    <OnboardingBackground id="mio-loginPage">
      <Login disclaimer={getDisclaimer(location.state)} />
      <FormFooter>
        <Text className="text-grey" size="small" mobile>
          Tu n'as pas de compte ?{' '}
          <Link className="text-link" to="/signup">
            Inscris-toi
          </Link>
        </Text>
        <Text className="text-grey" size="small" mobile>
          Tu as oublié ton mot de passe ?{' '}
          <Link className="text-link" to="/reset">
            Réinitialise-le
          </Link>
        </Text>
      </FormFooter>
    </OnboardingBackground>
  );
}
