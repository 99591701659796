import React from 'react';
import { Bloc, Text } from '@mangas-io/design-system';
import { useResponsive, useFavorites } from 'hooks';
import Icon from '@mdi/react';

import { mdiPlaylistPlus } from '@mdi/js';
import { MangaGrid } from 'Components';
import './FavoritePage.scss';
import { Page, Responsive } from 'UI';

// import headerImg from 'assets/favorites-header.png';

export default function FavoritePage() {
  const { is } = useResponsive();
  const isMedium = is('md');
  const { loaded, favorites } = useFavorites();
  return (
    <Page id="Favorites">
      <Text
        id="page-title"
        type="display"
        size={isMedium ? 'large' : 'medium'}
        mobile={isMedium ? 'false' : 'true'}
        bold
      >
        Ma liste
      </Text>
      <MangaGrid loading={!loaded} mangas={favorites} loadingCards={3} />
      {loaded && favorites.length === 0 ? (
        <div className="bloc-empty-list">
          <Bloc id="empty-list-container">
            <Icon id="empty-list-icon" path={mdiPlaylistPlus} width="30" />
            <Responsive>
              <Text id="empty-list-text" size="large" bold>
                Ta liste est vide
              </Text>
            </Responsive>
            <Responsive>
              <Text size="medium">
                Tu peux ajouter un manga en cliquant sur le bouton “ajouter” sur
                la page d’un manga
              </Text>
            </Responsive>
          </Bloc>
        </div>
      ) : null}
    </Page>
  );
}
