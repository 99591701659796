import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useAppState, favoritesSelector } from 'hooks';

const GQL_GET_FAVORITES = gql`
  query getFavorites {
    favorites {
      _id
      title
      slug
      thumbnail {
        url
      }
      categories {
        label
        level
      }
    }
  }
`;

const GQL_ADD_FAVORITE = gql`
  mutation addToFavorites($mangaId: ID!) {
    addToFavorites(mangaId: $mangaId) {
      _id
      title
      slug
      thumbnail {
        url
      }
      categories {
        label
        level
      }
    }
  }
`;

const GQL_REMOVE_FAVORITE = gql`
  mutation removeFromFavorites($mangaId: ID!) {
    removeFromFavorites(mangaId: $mangaId) {
      _id
      title
      slug
      thumbnail {
        url
      }
      categories {
        label
        level
      }
    }
  }
`;

export default function useFavorites() {
  const [state, actions] = useAppState(favoritesSelector);

  const { refetch } = useQuery(GQL_GET_FAVORITES, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const refreshFavorites = async () => {
    const {
      data: { favorites },
    } = await refetch();
    actions.setFavoritesAction(favorites);
  };

  const isFavorite = mangaId => {
    return state !== null && state.some(favorite => favorite._id === mangaId);
  };

  const [addToFavorites, { loading: addLoading }] = useMutation(
    GQL_ADD_FAVORITE,
    {
      onCompleted: res => {
        actions.addFavoriteAction(res.addToFavorites);
      },
    }
  );
  const [removeFromFavorites, { loading: removeLoading }] = useMutation(
    GQL_REMOVE_FAVORITE,
    {
      onCompleted: res => {
        actions.removeFavoriteAction(res.removeFromFavorites);
      },
    }
  );

  return {
    favorites: state,
    isFavorite,
    addToFavorites,
    removeFromFavorites,
    loaded: state !== null,
    loading: addLoading || removeLoading,
    refreshFavorites,
  };
}
