import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Text, Input, Button } from '@mangas-io/design-system';
import classnames from 'classnames';

import { useMutation } from 'hooks';
import { FloatingMenu } from 'UI';
import { GraphqlShield } from 'utils';

import './RemoveAccountCodeSlide.scss';

const POST_DELETE_ME = gql`
  mutation deleteMe($token: String!) {
    deleteMe(token: $token)
  }
`;

function refreshPage() {
  window.location.reload(false);
}

function getErrorMessage(errors) {
  if (!errors) return null;
  if (errors.includes('INVALID_TOKEN')) {
    return 'Code invalide';
  } else {
    return 'Une erreur est survenue';
  }
}

const RemoveAccountCodeSlide = props => {
  const { navigation, className, errors } = props;
  const [validation, setValidation] = useState(false);
  const [code, setCode] = useState('');

  const [deleteMe] = useMutation(POST_DELETE_ME, {});

  const handleOnPress = async e => {
    e.preventDefault();
    try {
      await deleteMe({
        variables: {
          token: code,
        },
      });
      setCode('');
      setValidation(true);
    } catch (e) {
      console.log(e, 'catched');
    }
  };

  if (validation)
    return (
      <div>
        <FloatingMenu.Header className="clickable">
          <Text>Supprimer mon compte</Text>
        </FloatingMenu.Header>
        <FloatingMenu.Body>
          <div className="validation-message">
            <Text>Le compte a bien été supprimé !</Text>
            <Button label="Retour" onClick={() => refreshPage()} />
          </div>
        </FloatingMenu.Body>
      </div>
    );
  return (
    <div
      className={classnames(
        className,
        'slide',
        'cancel-subscription-code-slide'
      )}
    >
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <Text>Supprimer mon compte</Text>
      </FloatingMenu.Header>

      <FloatingMenu.Body>
        <Text className="description">
          Un code de suppression t'a été envoyé par mail. Merci de le renseigner
          ci-dessous pour supprimer ton compte.
        </Text>
        <Input
          className="input"
          label="Code de suppression"
          highlight={errors.length > 0 ? 'error' : null}
          onChange={e => setCode(e.target.value)}
        />
        {errors &&
          errors.map(error => (
            <Text className="error">{getErrorMessage(error)}</Text>
          ))}
        <Button
          label="Confirmer le code"
          variant="primary"
          fullWidth
          onClick={handleOnPress}
        />
      </FloatingMenu.Body>
    </div>
  );
};

function RemoveAccountCodeShield(props) {
  return (
    <GraphqlShield>
      {(errors = []) => <RemoveAccountCodeSlide {...props} errors={errors} />}
    </GraphqlShield>
  );
}

export default RemoveAccountCodeShield;
