import React from 'react';
import Icon from '@mdi/react';
import { mdiBookmarkOutline } from '@mdi/js';
import { Bloc } from '@mangas-io/design-system';
import { ResponsiveText } from 'UI';
import { MangaLine } from 'Components';
import { CustomErrorBoundary } from 'utils';
import { useFavorites, useAuth } from 'hooks';
import './MaListeLine.scss';

function NoFavorite() {
  return (
    <div className="noFav">
      <ResponsiveText type="display" size="small" className="--title-s title">
        Ma liste 💖
      </ResponsiveText>
      <Bloc className="noFav-bloc">
        <ResponsiveText bold>Ta liste est vide !</ResponsiveText>
        <ResponsiveText className="noFav-text">
          Tu peux ajouter un manga en cliquant sur le bouton{' '}
          <Icon path={mdiBookmarkOutline} size={1} /> sur la page d'un manga
        </ResponsiveText>
      </Bloc>
    </div>
  );
}

function MaListeLine() {
  const { loading, favorites } = useFavorites();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) return null;
  if (loading) return <MangaLine loading />;
  if (!favorites) return null;
  const orderedFavorites = favorites && [...favorites].reverse();

  if (orderedFavorites.length === 0) return <NoFavorite />;
  return <MangaLine mangas={orderedFavorites} title="Ma liste 💖" />;
}

export default function MaListeLineShielded(props) {
  return (
    <CustomErrorBoundary>
      <MaListeLine {...props} />
    </CustomErrorBoundary>
  );
}
